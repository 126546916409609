// import React, { useRef, useEffect, useState, useContext } from "react";
import { useRef, useEffect, useState } from "react";
// PLUGINS
import { Navigation, Pagination, Autoplay, SwiperOptions, Mousewheel } from "swiper";
import { Swiper } from "swiper/react/swiper-react";
// ICONS

// STYLES
import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/autoplay/autoplay.min.css";
import "swiper/modules/mousewheel/mousewheel";

import "./Carousel.css";
import { setTimeout } from "timers";


type props = SwiperOptions & {
  slidePic: number,
  children?: JSX.Element | JSX.Element[];
  autoPlay?: boolean;
  showButtons?: boolean;
  onChangeSlide,
  onTransitionSwiper,
};

const Carousel = ({
  slidePic,
  children,
  loop = true,
  autoPlay = true,
  spaceBetween = 0,
  slidesPerView = 1,
  showButtons = true,
  pagination = true,
  onChangeSlide,
  onTransitionSwiper,
  hashNavigation = {
    watchState: true
  },
  ...otherProps
}: props): JSX.Element => {
  const navigationPrevRef = useRef(null);

  const navigationNextRef = useRef(null);

  // const [showFade, setShowFage] = useState(false);
  const [swiperFlag, setSwiperFlag] = useState<any>(null);

  useEffect(() => {
    window.onscroll=(function (e:any) {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop === 0) {
        swiperFlag?.enable();
        swiperFlag?.slideTo(swiperFlag);
      }
    })
  }, [slidePic, swiperFlag])

  const onTransitionSwipers = (type) => {
    onTransitionSwiper(type);
  }

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    setTimeout(() => {
      swiperFlag?.slideTo(slidePic);
      if (slidePic === 3) {
        swiperFlag?.disable();
        document.documentElement.scrollTop = 1;
      } else {
        swiperFlag?.enable();
      }
    }, 0)

  }, [slidePic, swiperFlag])

  return (
    <div
      className="swiper-container"
      style={{ position: "relative", minWidth: 0 }}
    > 
      {/* {showFade && <div className="fademask"></div>} */}
      <Swiper
        modules={[Navigation, Pagination, Autoplay, Mousewheel]}
        pagination={pagination && { clickable: true }}
        loop={loop}
        mousewheel={{
            thresholdDelta: 20
        }}
        effect="fade"
        fadeEffect={{
          crossFade: true
        }}
        speed={400}
        freeMode
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current
        }}
        autoplay={autoPlay && { delay: 2000 }}
        spaceBetween={spaceBetween}
        slidesPerView={slidesPerView}
        onInit={(swiper: any) => {
          setSwiperFlag(swiper);
          swiper.params.navigation.prevEl = navigationPrevRef.current;
          swiper.params.navigation.nextEl = navigationNextRef.current;
          swiper.navigation.destroy();
          swiper.navigation.init();
          swiper.navigation.update();
        }}
        onSwiper={(swiper: any) => {
          setSwiperFlag(swiper);
        }}
        onTouchStart={(e:any) => {
          // setShowFage(true);
          onTransitionSwipers(false);
        }}
        onTouchEnd={(e:any) => {
          // setShowFage(false);
          onTransitionSwipers(true);
        }}
        onTransitionStart={(e:any) => {
          onTransitionSwipers(false);
        }}
        onTransitionEnd= {() => {
          onTransitionSwipers(true);
        }}
        onScroll={(e:any) => {
            onChangeSlide(e.realIndex);

            if (e.realIndex === 3) {
              e.disable();
            }
        }}
        {...otherProps}
      >
        {children}

        <div style={!showButtons ? { display: "none" } : {}}>
          <button
            type="button"
            className="swiper-btn-prev"
            ref={navigationPrevRef}
          >
            prev
          </button>

          <button
            type="button"
            className="swiper-btn-next"
            ref={navigationNextRef}
          >
            next
          </button>
        </div>
      </Swiper>
    </div>
  );
};

export default Carousel;
