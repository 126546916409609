import React, { useState } from "react";
import styled from "styled-components";

import ActionModal from "./Modal/ActionModal";
import {
  getExplorerURI,
  VaultAddressMap,
  VaultOptions,
  VaultVersion,
  getVaultChain,
} from "shared/lib/constants/constants";
import { useCallback } from "react";
import VaultV1ActionsForm from "./VaultV1ActionsForm";
import VaultV2ActionsForm from "./VaultV2ActionForm";
import { BaseLink, Title } from "shared/lib/designSystem";
import { getVaultColor } from "shared/lib/utils/vault";
import { truncateAddress } from "shared/lib/utils/address";
import { ExternalIcon } from "shared/lib/assets/icons/icons";

const ContractButton = styled.div<{ color: string }>`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  background: rgba(105,236,206, 0.2);
  border: 1px solid #3F6A66;
`;

const ContractButtonTitleName = styled.div`
  background: #10312F;
  line-height: 56px;
  text-align: center;
  color: #ffffff;
  font-size: 18px;
`

const ContractButtonTitle = styled(Title)`
  letter-spacing: 1px;
  color: #69ECCE;
  font-size: 18px;
`;

const TradeTitle = styled.div`
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  color: #FFFFFF;
  margin-bottom: 24px;
`

interface DesktopActionFormProps {
  vault: {
    vaultOption: VaultOptions;
    vaultVersion: VaultVersion;
  };
}

const DesktopActionForm: React.FC<DesktopActionFormProps> = ({ vault }) => {
  const [showActionModal, setShowActionModal] = useState(false);

  const renderForm = useCallback(() => {
    switch (vault.vaultVersion) {
      case "v1":
        return (
          <VaultV1ActionsForm
            variant="desktop"
            vaultOption={vault.vaultOption}
            onFormSubmit={() => setShowActionModal(true)}
          />
        );
      case "v2":
        return (
          <VaultV2ActionsForm
            vaultOption={vault.vaultOption}
            onFormSubmit={() => setShowActionModal(true)}
          />
        );
    }
  }, [vault]);

  return (
    <div> 
      <TradeTitle>Deposit & Withdraw into vault</TradeTitle>
      <ActionModal
        vault={vault}
        variant={"desktop"}
        show={showActionModal}
        onClose={() => setShowActionModal(false)}
      />
      {renderForm()}

      {VaultAddressMap[vault.vaultOption][vault.vaultVersion] && (
        <BaseLink
          to={`${getExplorerURI(
            getVaultChain(vault.vaultOption)
          )}/address/${VaultAddressMap[vault.vaultOption][
            vault.vaultVersion
          ]!}`}
          target="_blank"
          rel="noreferrer noopener"
          className="w-100"
        >
          <ContractButton color={getVaultColor(vault.vaultOption)}>
            <ContractButtonTitleName className="col-4">Contract</ContractButtonTitleName>
            <div className="d-flex col-8 justify-content-center align-items-center">
              <ContractButtonTitle
                fontSize={14}
                lineHeight={20}
                color={getVaultColor(vault.vaultOption)}
                className="mr-2"
              >
                {`${truncateAddress(
                  VaultAddressMap[vault.vaultOption][vault.vaultVersion]!
                )}`}
              </ContractButtonTitle>
              <ExternalIcon color='#69ECCE' />
            </div>
            
          </ContractButton>
        </BaseLink>
      )}
    </div>
  );
};

export default DesktopActionForm;
