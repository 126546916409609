import { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useRouteMatch } from "react-router-dom";
// import { useWeb3Wallet } from "shared/lib/hooks/useWeb3Wallet";

import HeaderLogo from "./HeaderLogo";
import colors from "shared/lib/designSystem/colors";
import sizes from "shared/lib/designSystem/sizes";
import { Title, BaseLink } from "shared/lib/designSystem";
import MenuButton from "shared/lib/components/Common/MenuButton";
import { NavItemProps, MobileMenuOpenProps } from "./types";
// import AccountStatus from "../Wallet/AccountStatus";
import theme from "shared/lib/designSystem/theme";
import MobileOverlayMenu from "shared/lib/components/Common/MobileOverlayMenu";
// import NetworkSwitcherButton from "../NetworkSwitcher/NetworkSwitcherButton";
// import NotificationButton from "../Notification/NotificationButton";
// import { isEthNetwork } from "shared/lib/constants/constants";
import ExternalLinkIcon from "shared/lib/assets/icons/externalLink";
import LaunchApp from "./LaunchApp";

import useIsMobile from "../../hooks/useIsMobile";

const HeaderContainer = styled.div<MobileMenuOpenProps>`
  width: 100%;
  height: ${theme.header.height}px;
  position: fixed !important;
  top: 0;
  z-index: 100000;

  @media (max-width: ${sizes.xl}px) {
    padding: 16px 24px;
    border-bottom: none;
  }
  
  background: ${(props) => (props.isBkshow ? '#043F3D' : '')};
  z-index: ${(props) => (props.isMenuOpen ? 50 : 10)};


  // The backdrop for the menu does not show up if we enable the backdrop-filter
  // for the header nav. To get around that, just set 'none'
  ${(props) => {
    if (props.isMenuOpen) {
      return null;
    }

    return `
      /*backdrop-filter: blur(40px);*/
      /**
       * Firefox desktop come with default flag to have backdrop-filter disabled
       * Firefox Android also currently has bug where backdrop-filter is not being applied
       * More info: https://bugzilla.mozilla.org/show_bug.cgi?id=1178765
       **/
      @-moz-document url-prefix() {
        background-color: #043f3d /*rgba(4,63,61,0);*/
      }
    `;
  }}

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    background:  rgba(4,63,61,0);  /*#043f3d*/
  }
`;

const LogoContainer = styled.div`
  padding-left: 40px;
  margin-right: auto;
  z-index: 1000;

  @media (max-width: ${sizes.xl}px) {
    padding-left: 0;
  }
`;

// const HeaderButtonContainer = styled.div`
//   display: flex;
//   margin-right: 8px;
//   z-index: 1;
// `;

const HeaderAbsoluteContainer = styled.div`
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;

  @media (max-width: ${sizes.xl}px) {
    display: none;
  }
`;

const LinksContainer = styled.div`
  display: flex;
`;

const NavItem = styled.div.attrs({
  className: "d-flex align-items-center justify-content-center",
})<NavItemProps>`
  padding: 0px 20px;
  height: 100%;
  opacity: ${(props) => (props.isSelected ? "1" : "1")};

  &:hover {
    opacity: ${(props) => (props.isSelected ? theme.hover.opacity : "1")};
  }

  @media (max-width: ${sizes.xl}px) {
    padding: 0px 0px 40px 48px;
  }
`;

const NavLinkText = styled(Title)`
  letter-spacing: 1.5px;
  line-height: 20px;
  text-transform: unset;
  font-family: DM Sans;
  font-weight: 600;
  line-height: 25px;
  color: #FFFFFF!important;

  @media (max-width: ${sizes.xl}px) {
    font-size: 16px;
  }
`;

const SecondaryMobileNavItem = styled.div`
  display: none;

  @media (max-width: ${sizes.xl}px) {
    display: flex;
    padding: 0px 0px 24px 48px;
  }
`;

const MobileOnly = styled.div`
  display: none;

  @media (max-width: ${sizes.xl}px) {
    display: flex;
  }
`;

const Header = (props) => {
  // const { active, chainId } = useWeb3Wallet();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [bkshow, setBkshow] = useState(false);
  const product = useRouteMatch({ path: "/products", exact: true });
  const portfolio = useRouteMatch({ path: "/portfolio", exact: true });
  // const staking = useRouteMatch({ path: "/staking", exact: true });
  const isMobileFlag = useIsMobile();

  const handleScroll = useCallback(event => {
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    if ((isMobileFlag && scrollTop >= 320 ) || (!isMobileFlag && scrollTop >= 20)) {
      setBkshow(true);
    } else {
      setBkshow(false);
    }

  }, [isMobileFlag])

  useEffect(()=> {
    //  绑定滚动元素并添加scroll事件，组件销毁时移除
    const scrollDom = window;
    scrollDom.addEventListener('scroll', handleScroll)
    return () => scrollDom.removeEventListener('scroll', handleScroll); 
  }, [handleScroll])



  const onToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const renderLinkItem = (
    title: string,
    to: string,
    isSelected: boolean,
    primary: boolean = true,
    external: boolean = false
  ) => {
    return (
      <BaseLink
        to={'/'}
        target={external ? "_blank" : undefined}
        rel={external ? "noreferrer noopener" : undefined}
        onClick={() => {
          props.changeSlide(to);
          if (!external) setIsMenuOpen(false);
        }}
      >
        {primary ? (
          <NavItem isSelected={isSelected}>
            <NavLinkText>{title}</NavLinkText>
            {external && (
              <ExternalLinkIcon
                style={{
                  marginLeft: 6,
                }}
              />
            )}
          </NavItem>
        ) : (
          <SecondaryMobileNavItem>
            <Title fontSize={18} color={`${colors.primaryText}7A`}>
              {title}
            </Title>
          </SecondaryMobileNavItem>
        )}
      </BaseLink>
    );
  };

  return (
    <HeaderContainer
      isBkshow={bkshow}
      isMenuOpen={isMenuOpen}
      className="d-flex align-items-center"
    >
      {/* LOGO */}
      <LogoContainer>
        <HeaderLogo />
      </LogoContainer>

      {/* LINKS */}
      <HeaderAbsoluteContainer>
        <LinksContainer>
          {renderLinkItem("Our Products", "#products", Boolean(product))}
          {renderLinkItem("Protocol Data", "#protocol-data", Boolean(portfolio))}
          {renderLinkItem("About Us", "#about", Boolean(product))}
          {/* {renderLinkItem("Partners", "#partners", Boolean(portfolio))} */}
          {/* {chainId &&
            isEthNetwork(chainId) &&
            renderLinkItem("STAKING", "/staking", Boolean(staking))} */}
          {/* {renderLinkItem(
            "GOVERNANCE",
            "https://vote.ribbon.finance/",
            false,
            true,
            true
          )} */}
        </LinksContainer>
      </HeaderAbsoluteContainer>
      <LaunchApp />

      {/* {active && (
        <HeaderButtonContainer>
          <NetworkSwitcherButton />
        </HeaderButtonContainer>
      )} */}

      {/* {active && (
        <HeaderButtonContainer>
          <NotificationButton />
        </HeaderButtonContainer>
      )} */}

      {/* <AccountStatus variant="desktop" /> */}
      {/* MOBILE MENU */}
      <MobileOnly>
        <MenuButton onToggle={onToggleMenu} isOpen={isMenuOpen} />
        <MobileOverlayMenu
          className="flex-column align-items-center justify-content-center"
          isMenuOpen={isMenuOpen}
          onClick={onToggleMenu}
          boundingDivProps={{
            style: {
              marginRight: "auto",
            },
          }}
        >
          {renderLinkItem(
            "Products",
            "/products",
            Boolean(useRouteMatch({ path: "/products", exact: true }))
          )}
          {renderLinkItem(
            "Protocol Data",
            "#protocol-data",
            Boolean(useRouteMatch({ path: "/portfolio", exact: true }))
          )}
          {renderLinkItem(
            "About Us",
            "#about",
            Boolean(useRouteMatch({ path: "/staking", exact: true }))
          )}
          {renderLinkItem(
            "Partners",
            "#partners",
            Boolean(useRouteMatch({ path: "/staking", exact: true }))
          )}
          {/*
          {renderLinkItem(
            "GOVERNANCE",
            "https://vote.ribbon.finance",
            false,
            true,
            true
          )}
          */}
          {/*
          {renderLinkItem(
            "DISCORD",
            "",
            false,
            false,
            false
          )}
          */}
          {renderLinkItem(
            "TWITTER",
            "https://twitter.com/BonsaiStrike",
            false,
            false,
            true
          )}
          {/*
          {renderLinkItem(
            "GITHUB",
            "https://github.com/ribbon-finance",
            false,
            false,
            true
          )}
          {renderLinkItem(
            "FAQ",
            "https://docs.ribbon.finance/faq",
            false,
            false,
            true
          )}
          {renderLinkItem(
            "BLOG",
            "https://medium.com/@ribbonfinance",
            false,
            false,
            true
          )}
          {renderLinkItem(
            "TERMS",
            "https://ribbon.finance/terms",
            false,
            false,
            true
          )}
          {renderLinkItem(
            "POLICY",
            "https://ribbon.finance/policy",
            false,
            false,
            true
          )}

          {renderLinkItem(
            "AUCTIONS",
            "https://auction.ribbon.finance",
            false,
            false,
            true
          )}
          */}
        </MobileOverlayMenu>
      </MobileOnly>
    </HeaderContainer>
  );
};

export default Header;
