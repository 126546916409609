import React, { useMemo, useState, 
  // useContext, 
  useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import currency from "currency.js";
import { Col, Row } from "react-bootstrap";
import moment from "moment";

import theme from "shared/lib/designSystem/theme";
import { useAssetsPrice } from "shared/lib/hooks/useAssetPrice";
import { formatOptionStrike } from "shared/lib/utils/math";
import { getAssetDecimals, getAssetDisplay } from "shared/lib/utils/asset";
import {
  getAssets,
  getDisplayAssets,
  getOptionAssets,
  VaultOptions,
  VaultVersion,
  getVaultChain,
} from "shared/lib/constants/constants";
import { BaseButton, SecondaryText, Title } from "shared/lib/designSystem";
import colors from "shared/lib/designSystem/colors";
import useLoadingText from "shared/lib/hooks/useLoadingText";
import StrikeChart from "./StrikeChart";
import ProfitCalculatorModal from "./ProfitCalculatorModal";
import { formatUnits } from "@ethersproject/units";
import { useLatestOption } from "shared/lib/hooks/useLatestOption";
import TooltipExplanation from "shared/lib/components/Common/TooltipExplanation";
import HelpInfo from "shared/lib/components/Common/HelpInfo";

// import { SubgraphDataContext } from "shared/lib/hooks/subgraphDataContext";


const VaultPerformanceChartContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 232px;
  background: #0A3836;
`;

const VaultPerformanceChartSecondaryContainer = styled.div`
  background: #0A3836;

  &:last-child {
    border-radius: 0px 0px ${theme.border.radius} ${theme.border.radius};
  }
`;

const DataCol = styled(Col)`
  display: flex;
  flex-direction: column;
  border-top: 1px solid #3F6A66;

  && {
    padding: 16px;
  }

  &:nth-child(even) {
  border-left: 1px solid #3F6A66;
  }
`;

const DataLabel = styled(SecondaryText)`
  font-size: 12px;
  line-height: 16px;
`;

const DataNumber = styled(Title)<{ variant?: "green" | "red" }>`
  font-size: 16px;
  line-height: 24px;
  margin-top: 4px;

  ${(props) => {
    switch (props.variant) {
      case "green":
        return `color: ${colors.green};`;
      case "red":
        return `color: ${colors.red};`;
      default:
        return ``;
    }
  }}
`;

const CalculatorButton = styled(BaseButton)<{ color: string }>`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 16px 0px;
  background: ${(props) => props.color};
  border-radius: 0px 0px ${theme.border.radius} ${theme.border.radius};

  &:hover {
    opacity: ${theme.hover.opacity};
  }
`;

const CalculatorButtonText = styled(Title)<{ color: string }>`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  color: ${(props) => props.color};
`;

interface WeeklyStrategySnapshotProps {
  vault: {
    vaultOption: VaultOptions;
    vaultVersion: VaultVersion;
  };
}

const WeeklyStrategySnapshot: React.FC<WeeklyStrategySnapshotProps> = ({
  vault,
}) => {
  const { vaultOption, vaultVersion } = vault;
  const { option: currentOption, loading: optionLoading } = useLatestOption(
    vaultOption,
    vaultVersion
  );

  type currentPriceType = {
    optionName: string,
    optionPrice: number
  }

  const [currentPriceObj, setCurrentPriceObj] = useState<currentPriceType>({optionName: '-', optionPrice: 0});

  useEffect(() => {
    const timestamp:any = Number(new Date()).valueOf();
    axios.get(`https://price-service.bttcdn.com/v2/asset/price?symbol=ETH,BUSD,BTC,BNB&${timestamp}`).then(res => {
      let data = res.data.data;
      let name = undefined;
      let optionPrice = 0;
      switch (vaultOption) {
        // case 'bETH-THETA':
        //   name = data.find(item => item.base === "ETH").base;
        //   optionPrice = data.find(item => item.base === "ETH").price;
        //   break;
        // case 'bBTC-THETA':
        //   name = data.find(item => item.base === "BTC").base;
        //   optionPrice = data.find(item => item.base === "BTC").price;
        //   break;
        // case 'bBUSD-ETH-P-THETA':
        //   name = data.find(item => item.base === "ETH").base;
        //   optionPrice = data.find(item => item.base === "ETH").price;
        //   break;
        case 'bBNB-THETA':
          name = data.find(item => item.base === "BNB").base;
          optionPrice = data.find(item => item.base === "BNB").price;
          break;
      }
      let optionName = `Current ${name} Price`;
      
      console.log('optionPrice')
      console.log({
        optionName,
        optionPrice
      })
      setCurrentPriceObj({
        optionName,
        optionPrice
      })
      return 
    });
  }, [vaultOption])

  const asset = getAssets(vaultOption);
  const optionAsset = getOptionAssets(vaultOption);
  const { prices, loading: priceLoading } = useAssetsPrice();
  const loading = currentPriceObj.optionPrice == 0 || optionLoading;
  const [showCalculator, setShowCalculator] = useState(false);
  const chain = getVaultChain(vaultOption);

  const loadingText = useLoadingText();

  const strikeAPRText = useMemo(() => {
    if (optionLoading) return loadingText;

    if (!currentOption) return "---";

    return currency(formatOptionStrike(currentOption.strike, chain)).format();
  }, [chain, currentOption, loadingText, optionLoading]);

  const toExpiryText = useMemo(() => {
    if (optionLoading) return loadingText;

    if (!currentOption) return "---";

    const toExpiryDuration = moment.duration(
      currentOption.expiry.diff(moment()),
      "milliseconds"
    );

    if (toExpiryDuration.asMilliseconds() <= 0) {
      return "Expired";
    }

    return `${toExpiryDuration.days()}D ${toExpiryDuration.hours()}H ${toExpiryDuration.minutes()}M`;
  }, [currentOption, loadingText, optionLoading]);

  const KPI = useMemo(() => {
    if (loading || !currentOption) {
      return undefined;
    }

    const higherStrike =
      formatOptionStrike(currentOption.strike, chain) > currentPriceObj.optionPrice!;
    const isExercisedRange = currentOption.isPut ? higherStrike : !higherStrike;
    const assetDecimals = getAssetDecimals(asset);

    let profit: number;

    if (!isExercisedRange) {
      profit = parseFloat(formatUnits(currentOption.premium, assetDecimals));
    } else if (currentOption.isPut) {
      const exerciseCost =
        formatOptionStrike(currentOption.strike, chain) - currentPriceObj.optionPrice!;

      profit =
        parseFloat(formatUnits(currentOption.premium, assetDecimals)) -
        currentOption.amount * exerciseCost;
    } else {
      profit =
        (currentOption.amount *
          formatOptionStrike(currentOption.strike, chain)) /
          currentPriceObj.optionPrice! -
        currentOption.amount +
        parseFloat(formatUnits(currentOption.premium, assetDecimals));
    }

    return {
      isProfit: profit >= 0,
      roi:
        (profit /
          parseFloat(formatUnits(currentOption.depositAmount, assetDecimals))) *
        100 *
        0.9,
    };
  }, [chain, loading, currentOption, currentPriceObj, optionAsset, asset]);

  const ProfitabilityText = useMemo(() => {
    if (loading) return loadingText;

    if (!KPI) return "---";

    return `${KPI.roi.toFixed(2)}%`;
  }, [KPI, loading, loadingText]);

  const strikeChart = useMemo(() => {



    if (loading) {
      return <Title>{loadingText}</Title>;
    }

    if (!currentPriceObj.optionPrice) {
      return <Title>No data</Title>;
    }

    if (!currentOption) {
      return <Title>No strike chosen</Title>;
    }
    console.group("strickPrice")
    // console.log(loading);
    // console.log(prices);
    // console.log(optionAsset);
    // console.log(KPI);
    console.log(currentOption);
    console.log(currentPriceObj.optionPrice)
    console.log(formatOptionStrike(currentOption.strike, chain))
    console.groupEnd();

    return (
      <StrikeChart
        current={currentPriceObj.optionPrice || 0}
        strike={formatOptionStrike(currentOption.strike, chain)}
        profitable={KPI ? KPI.isProfit : true}
      />
    );
  }, [chain, currentPriceObj, currentOption, optionAsset, KPI, loading, loadingText]);

  return (
    <>
      <VaultPerformanceChartContainer>
        {strikeChart}
      </VaultPerformanceChartContainer>
      <VaultPerformanceChartSecondaryContainer
      
      style={{
        maxWidth: "91vw",
      }}
      >
        <Row noGutters>
          <DataCol xs="6">
            <DataLabel className="d-block">
              {/* Current {getAssetDisplay(optionAsset)} Price */}
              {currentPriceObj.optionName}
            </DataLabel>
            <DataNumber
              variant={KPI ? (KPI.isProfit ? "green" : "red") : undefined}
            >
              {/* {priceLoading
                ? loadingText
                : currency(currentPriceObj.optionPrice!).format()} */}
              {currency(currentPriceObj.optionPrice, { precision: 2 }).format() }
            </DataNumber>
          </DataCol>
          <DataCol xs="6">
            <DataLabel className="d-block">
              Selected {getAssetDisplay(optionAsset)} Strike Price
            </DataLabel>
            <DataNumber>{strikeAPRText}</DataNumber>
          </DataCol>
          <DataCol xs="6">
            <div className="d-flex align-items-center">
              <DataLabel className="d-block">Last Week’s Performance</DataLabel>
              <TooltipExplanation
                title="This Week’s Performance"
                explanation={
                  <>
                    The {getAssetDisplay(asset)} premiums earned from selling
                    options expressed as a percentage of the amount of{" "}
                    {getAssetDisplay(getDisplayAssets(vaultOption))} used to
                    collateralize the options.
                    <br />
                    <br />
                    <SecondaryText color={colors.primaryText}>
                      Performance = (Premiums / Options Collateral)*100
                    </SecondaryText>
                    <br />
                    <br />
                    Fees are not included in this calculation.
                  </>
                }
                renderContent={({ ref, ...triggerHandler }) => (
                  <HelpInfo containerRef={ref} {...triggerHandler}>
                    i
                  </HelpInfo>
                )}
              />
            </div>
            <DataNumber
              variant={KPI ? (KPI.isProfit ? "green" : "red") : undefined}
              className="w-100"
            >
              {ProfitabilityText}
            </DataNumber>
          </DataCol>
          <DataCol xs="6">
            <DataLabel className="d-block">Time to Expiry</DataLabel>
            <DataNumber>{toExpiryText}</DataNumber>
          </DataCol>
        </Row>
      </VaultPerformanceChartSecondaryContainer>
      {currentOption && (
        <>
          <CalculatorButton
            color={colors.buttons.secondaryBackground2}
            role="button"
            onClick={() => setShowCalculator(true)}
          >
            <CalculatorButtonText color={colors.buttons.secondaryText}>
              OPEN PROFIT CALCULATOR
            </CalculatorButtonText>
          </CalculatorButton>
          <ProfitCalculatorModal
            vault={vault}
            show={showCalculator}
            onClose={() => setShowCalculator(false)}
            prices={prices}
            asset={asset}
            optionAsset={optionAsset}
            currentOption={currentOption}
          />
        </>
      )}
    </>
  );
};

export default WeeklyStrategySnapshot;
