import { Web3ReactProvider } from "@web3-react/core";
import React, { useEffect } from "react";
import smoothscroll from "smoothscroll-polyfill";
import {
  getPhantomWallet,
  getSolflareWallet,
} from "@solana/wallet-adapter-wallets";
import { WalletProvider as SolanaWalletProvider } from "@solana/wallet-adapter-react";

import RootApp from "./components/RootApp";
import { Web3ContextProvider } from "shared/lib/hooks/web3Context";
// import { ConnectionProvider } from "@solana/wallet-adapter-react";
import { getLibrary } from "shared/lib/utils/getLibrary";
// import { getSolanaClusterURI } from "shared/lib/utils/env";
import { Web3DataContextProvider } from "shared/lib/hooks/web3DataContext";
import { SubgraphDataContextProvider } from "shared/lib/hooks/subgraphDataContext";
import { PendingTransactionsContextProvider } from "shared/lib/hooks/pendingTransactionsContext";
import { ExternalAPIDataContextProvider } from "shared/lib/hooks/externalAPIDataContext";
import { ChainContextProvider } from "shared/lib/hooks/chainContext";
import { GeofenceCountry, useGeofence } from "shared/lib/hooks/useGeofence";
// import TextPreview from "shared/lib/components/TextPreview/TextPreview";
import Geoblocked from "shared/lib/components/Geoblocked/Geoblocked";
// import { LoadingText } from "shared/lib/hooks/useLoadingText";
import { MaskLayer } from "./components/Common/MaskLayer";
import "shared/lib/i18n/config";

import tree from "./assets/images/main-icon.svg";

import useIsMobile from "./hooks/useIsMobile";

const SOLANA_WALLETS = [getPhantomWallet(), getSolflareWallet()];



export const MobileContext = React.createContext({isMobileFlag: false});


function App() {
  const { loading, rejected } = useGeofence(GeofenceCountry.SG);
  const isMobileFlag = useIsMobile();

  useEffect(() => {
    smoothscroll.polyfill();
  }, []);


  if (!loading) {
    const wrap = document.getElementById("wrap");
    wrap?.classList.add('animation1')
  }

  if (rejected) {
    const treeLogo = (<img src={tree} height={80} width={80}  alt="" />)
    return <Geoblocked treeLogo={treeLogo}/>;
  }

  // if (loading) {
  //   return (
  //     <TextPreview>
  //       <LoadingText>Coda Finance</LoadingText>
  //     </TextPreview>
  //   );
  // } else if (!loading && rejected) {
  //   return <Geoblocked />;
  // }

  return (
    <ChainContextProvider>
      <MaskLayer loading={loading}/>
      {/* <ConnectionProvider endpoint={getSolanaClusterURI()}> */}
        {/* TODO: We only enable autoConnect when Solana is production ready as to not prompt the user */}
        <SolanaWalletProvider wallets={SOLANA_WALLETS} autoConnect={true}>
          <Web3ContextProvider>
            <Web3ReactProvider getLibrary={getLibrary}>
              <PendingTransactionsContextProvider>
                <Web3DataContextProvider>
                  <SubgraphDataContextProvider>
                    <ExternalAPIDataContextProvider>
                      <MobileContext.Provider value={{isMobileFlag}}>
                        <RootApp />
                      </MobileContext.Provider>
                    </ExternalAPIDataContextProvider>
                  </SubgraphDataContextProvider>
                </Web3DataContextProvider>
              </PendingTransactionsContextProvider>
            </Web3ReactProvider>
          </Web3ContextProvider>
        </SolanaWalletProvider>
      {/* </ConnectionProvider> */}
    </ChainContextProvider>
  );
}

export default App;
