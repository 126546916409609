import "./common.css";
import "./index.css";

import tree from "./image/loadingIcon.gif";
// import tree from "./image/tree-rotation-700k.mp4";
import path3 from "./image/path3.svg";
import path3Mobile from "./image/path3Mobile.png";
import path4 from "./image/path4.svg";
import path4Mobile from "./image/path4Mobile.png";
// import github from "./image/github.svg";
// import medium from "./image/medium.svg";

import Grid from '@mui/material/Grid'; // Grid version 1
import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';

import Fade from 'react-reveal/Fade';
// import Slide from 'react-reveal/Slide';

// import OurInvestors from "./OurInvestors";

const Description = (props) => {
    return (
        <>  
          <Grid container spacing={0} columns={{md: 24, xs:16}} className="home-part-4"
            style={{
              backgroundImage: `url(${props.isMobile ? path4Mobile : path4})`,
            }} id="about">
            <Grid xs={16} md={8} className="image">
              
              <img src={tree} className="bonsai-tree row text-center mx-auto" alt="Bonsai Tree"></img>
             
              {/* <video className="bonsai-tree row text-center mx-auto p-5" style={{width: '100%'}} muted autoPlay loop>
                <source src={tree} type="video/mp4" />
              </video> */}
            </Grid>
            <Grid xs={16} md={10} className="text">
              <Fade big>
                <Typography variant="h4" className="head1 mt-5 mb-4">
                Bonsai&nbsp;Strike&nbsp;is&nbsp;a decentralized&nbsp;options protocol&nbsp;providing <span style={{color: '#25AE7E'}}>
                  sustainable&nbsp;real&nbsp;yield 
                  </span>&nbsp;across market&nbsp;cycles.
                </Typography>
              </Fade>
              <Fade big>
                <Typography variant="body1" className="paragraph1 mb-4">
                Bonsai Strike is a Decentralised Options Vault offering sustainable and real yield on Binance Smart Chain. It is the first in a planned series of yield generating DeFi products by the team at CODA Ventures, a graduate of the 4th Binance Labs Incubation Program.
                </Typography>
                {/* <Typography variant="h5" className="my-4">
                  Trust, but verify.
                </Typography> */}
              </Fade>
              {/* <Button variant="contained" size="large" color="success" className="launch-button my-2 mr-3 disabled desBut">
                <img src={github} alt="github" style={{marginRight: 8, width: 24}}/>
                View Us on GitHub
              </Button>
              <Button variant="contained" size="large" color="success" className="launch-button my-2 disabled desBut">
              <img src={medium} alt="medium" style={{marginRight: 8, width: 24}}/>
                View Us on Medium
              </Button> */}
            </Grid>
            <img className="flodLine3" src={props.isMobile ? path3Mobile : path3} alt="" />
          </Grid>
          {/* <OurInvestors /> */}
        </>
    )
}

export default Description;