// import chain_logo_bsc from './image/chain_logo_bsc.png';
// import bg1 from './image/builtforbsc_bg_1.png';
import lock from './image/lock.png';
import paid from './image/paid.png';
import graph from './image/graph.png';
import path2 from "./image/path2.svg";
import path2Mobile from "./image/path2Mobile.png";
import "./common.css";
import "./index.css";
import Grid from '@mui/material/Grid'; // Grid version 1
import Typography from '@mui/material/Typography';
// import CountUp from 'react-countup';
import Slide from 'react-reveal/Slide';

const ProtocolData = (props) => {
    // function changeText(id:string, content:string){
    //   let element = document.getElementById(id);
    //   if (element != null) {
    //     element.innerHTML = content;
    //   }
    // }
    return (
        <>
          <Grid className="home-part-3" id="protocol-data">
            <Grid container className="mb-4">
              <Grid item xs={12} className="text-center">
                <Typography variant="h3" className="head3">
                    Protocol Data
                </Typography>
                <Typography variant="body1" className="paragraph1">
                  Watch the growth of the Bonsai Strike protocol in real time.
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={0} columns={{ md: 21, xs:12}} className="pt-3 trio mt-4">
              <Grid item xs={0} md={3}>
              </Grid>
              <Grid item xs={12} md={5} className="text-center">
                <Slide bottom>
                  <img
                    className="text-center mb-5"
                    src={lock}
                    alt="Total Value Locked"
                  />
                  <Typography variant="h6" className="">
                    Total Value Locked
                  </Typography>
                  <Typography variant="h4" className="head4">
                    {/* <CountUp start={0} end={20} delay={0} duration={3} prefix="$" suffix="m" enableScrollSpy scrollSpyDelay={0} onUpdate={() => {changeText("data-1", "X0m");}}>
                      {({ countUpRef }) => (
                        <div>
                          <span id="data-1" ref={countUpRef} />
                        </div>
                      )}
                    </CountUp> */}
                    Coming Soon
                  </Typography>
                </Slide>
              </Grid>
              <Grid item xs={12} md={5} className="text-center">
                <Slide bottom>
                  <img
                    className="text-center mb-5"
                    src={paid}
                    alt="Premium Paid to Users"
                  />
                  <Typography variant="h6" className="">
                    Premium Paid to Users
                  </Typography>
                  <Typography variant="h4" className="head4">
                    {/* <CountUp start={0} end={3} decimals={1} delay={0} duration={3} prefix="$" suffix="m" enableScrollSpy scrollSpyDelay={0} onUpdate={() => {changeText('data-2', 'Xm')}}>
                      {({ countUpRef }) => (
                        <div>
                          <span id="data-2" ref={countUpRef} />
                        </div>
                      )}
                    </CountUp> */}
                    Coming Soon
                  </Typography>
                </Slide>
              </Grid>
              <Grid item xs={12} md={5} className="text-center">
                <Slide bottom>
                  <img
                    className="text-center mb-5"
                    src={graph}
                    alt="Growth of Option Sales"
                  />
                  <Typography variant="h6" className="">
                    Growth of Option Sales
                  </Typography>
                  <Typography variant="h4" className="head4">
                    {/* <CountUp start={0} end={20} delay={0} duration={3} prefix="$" suffix="m" enableScrollSpy scrollSpyDelay={0} onUpdate={() => {changeText('data-3', 'X0m')}}>
                      {({ countUpRef }) => (
                        <div>
                          <span id="data-3" ref={countUpRef} />
                        </div>
                      )}
                    </CountUp> */}
                    Coming Soon
                  </Typography>
                </Slide>
              </Grid>
              <Grid item xs={0} md={3}>
              </Grid>
            </Grid>

            <img className="flodLine2" src={props.isMobile ? path2Mobile : path2} alt="" />
          </Grid>
        </>
    )
}

export default ProtocolData;