import React from "react";
import styled, { keyframes } from "styled-components";
import tree from '../../assets/images/main-icon.svg';

const flashing = () => keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }

}`;

const Wrapper = styled.div`
  width: 6rem;
  height: 6rem;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  overflow: hidden;
  img {
    width: 6rem;
    height: 6rem;
    position: absolute;
    top: 0;
    left: 0;
  }
  &:before {
    display: inline-block;
    width: 6rem;
    height: 6rem;
    content: "";
    background: rgba(16,49,47,0.6);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    animation: ${flashing} 1.5s ease-in-out infinite;
  }
`;


export const LoadingIcon: React.FC = () => {
  return (
    <>
    <Wrapper>
      <img src={tree} alt=""/>
    </Wrapper> 
    </>
  );
};
