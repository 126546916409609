import { useEffect, useState } from "react";


const useIsMobile = () => {
  const [isMobileStatus, setIsMobildStatus] = useState(false);
  useEffect(() => {
    if((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
      setIsMobildStatus(true);
    }
  if (document.body.clientWidth < 800) {
      setIsMobildStatus(true);
  }
  }, [])
  return isMobileStatus;
};

export default useIsMobile;


