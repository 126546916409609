import 
// React, 
{ useContext, useState } from "react";
import { BrowserRouter as Router, Route, Switch, 
  // useRouteMatch 
} from "react-router-dom";
// import { matchPath } from 'react-router';
import styled from "styled-components";

import { MobileContext } from '../App';

// import Header from "./Header/Header";
import HeaderV2 from "./HeaderV2/Header";
import HeaderV3 from "./HeaderV3/Header";
import Homepage from "../pages/Home/Homepage";
// import HomepageV2 from "../pages/HomeV2/Homepage";
import HomepageV4 from "../pages/HomeV4/Homepage";
import HomepageMobile from "../pages/HomeV4/HomepageMobile";
import DepositPage from "../pages/DepositPage/DepositPage";
import useEagerConnect from "shared/lib/hooks/useEagerConnect";
import PortfolioPage from "../pages/Portfolio/PortfolioPage";
// import Footer from "./Footer/Footer";
// import FooterV2 from "./FooterV2/Footer";
// import FooterV3 from "./FooterV3/Footer";
import useScreenSize from "shared/lib/hooks/useScreenSize";
import { TxStatusToast, WithdrawReminderToast } from "./Common/toasts";
import WalletConnectModal from "./Wallet/WalletConnectModal";
// import StakingPage from "../pages/Staking/StakingPage";
import NotFound from "shared/lib/pages/NotFound";
// import colors from "shared/lib/designSystem/colors";
import YourPositionModal from "./Vault/Modal/YourPositionModal";

const Root = styled.div<{ screenHeight: number }>`
  background-color: '#043f3d';
  min-height: ${(props) =>
    props.screenHeight ? `${props.screenHeight}px` : `100vh`};
`;

const RootApp = () => {
  useEagerConnect();
  const { height: screenHeight } = useScreenSize();
  const isMobileFlag = useContext(MobileContext).isMobileFlag;
  const [showHeader, setShowHeader] = useState(true);
  const [slide, setSlide] = useState(0);

  const onTransitionSwiper = (flag) => {
    setShowHeader(flag);
  }

  const changeSlide = (flag) => {
    // console.log(flag)
    switch (flag) {
      case '#products':
        setSlide(1);
        break;
      case '#protocol-data':
        setSlide(2);
        break;
      case '#about':
        setSlide(3);
        break;
      // case '#partners':
      //   setSlide(3);
      //   break;
      default:
        // setSlide(0);
        // break;
    }
  }

  return (
    <Root id="appRoot" screenHeight={screenHeight}>
      <WalletConnectModal />
      <YourPositionModal />

      <Router>
        {/* <Header /> */}
        {/* <HeaderV2 /> */}
        {/*
        {showHeader && isHomepage && <HeaderV3 />}
        {!isHomepage && <HeaderV2 />}
        */}
        <WithdrawReminderToast />
        <TxStatusToast />
        <Switch>
        <Route path="/" exact>
          {showHeader && <HeaderV3 changeSlide={changeSlide}/>}
          {!isMobileFlag ? <HomepageV4 slidepic={slide} onTransitionSwiper={onTransitionSwiper}/> : <HomepageMobile />}
          </Route>
          <Route path="/products" exact>
            <HeaderV2 />
            <Homepage />
          </Route>
          <Route path="/theta-vault/:vaultSymbol">
            <HeaderV2 />
            <DepositPage />
          </Route>
          <Route path="/v2/theta-vault/:vaultSymbol">
            <HeaderV2 />
            <DepositPage />
          </Route>
          <Route path="/portfolio">
            <HeaderV2 />
            <PortfolioPage />
          </Route>
          {/* <Route path="/staking">
            <HeaderV2 />
            <StakingPage />
          </Route> */}
          <Route>
            <HeaderV2 />
            <NotFound />
          </Route>
        </Switch>
        {/* <Footer /> */}
        {/* <FooterV2 /> */}
        {/* <FooterV3 /> */}
      </Router>
    </Root>
  );
};

export default RootApp;
