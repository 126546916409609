import React from "react";
import styled from "styled-components";

// import { Title } from "shared/lib/designSystem";
import sizes from "shared/lib/designSystem/sizes";
import useScreenSize from "shared/lib/hooks/useScreenSize";
import { SortBy, ActivityFilter, activityFilters, sortByList } from "./types";
import FilterDropdown from "shared/lib/components/Common/FilterDropdown";

// const Header = styled(Title)`
//   font-size: 18px;
//   margin-right: 24px;

//   @media (max-width: ${sizes.sm}px) {
//     width: 100%;
//     margin-bottom: 16px;
//   }
// `;

const ContentTittle = styled.div`
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 16px;
  padding-top:80px; 
  margin-top:-80px;
`

interface VaultActivityHeaderProps {
  activityFilter: ActivityFilter;
  setActivityFilter: React.Dispatch<React.SetStateAction<ActivityFilter>>;
  sortBy: SortBy;
  setSortBy: React.Dispatch<React.SetStateAction<SortBy>>;
}

const VaultActivityHeader: React.FC<VaultActivityHeaderProps> = ({
  activityFilter,
  setActivityFilter,
  sortBy,
  setSortBy,
}) => {
  const { width } = useScreenSize();

  return (
    <div className="d-flex justify-content-between align-items-center mb-4">
      <ContentTittle id="vaultActivity">Vault Activity</ContentTittle>
      <div className="d-flex justify-content-center" style={{width: 438}}>
        <FilterDropdown
          // @ts-ignore
          options={activityFilters}
          value={activityFilter}
          // @ts-ignore
          onSelect={setActivityFilter}
          dropdownMenuConfig={{
            horizontalOrientation: width > sizes.md ? "right" : "left",
            topBuffer: 8,
          }}
          buttonConfig={{
            color: '#ffffff'
          }}
        />
        <FilterDropdown
          // @ts-ignore
          options={sortByList}
          value={sortBy}
          // @ts-ignore
          onSelect={setSortBy}
          className="ml-3"
          buttonConfig={{
            color: '#ffffff'
          }}
        />
      </div>

    </div>
  );
};

export default VaultActivityHeader;
