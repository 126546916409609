import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

// import { AppLogo } from "shared/lib/assets/icons/logo";
// import logo from '../../assets/images/logo.png'
import logo from '../../assets/images/main-icon.svg'
import LogoText from '../../assets/images/logo-text.png'

const LogoContainer = styled.div`
  display: flex;
  border-radius: 48px;
`;

const TreeImg = styled.img`
  height: 40px;
  width: 40px;
  @media (max-width: 768px) {
    width: 29px;
    height: 27px;
  }
`
const TextImg = styled.img`
  width: 128px;
  height: 40px;
  @media (max-width: 768px) {
    width: 82px;
    height: 27px;
  }
`

const HeaderLogo = () => {
  return (
    <>
      <LogoContainer>
        <Link to="/">
          {/* <AppLogo height="40px" width="40px" /> */}
          <TreeImg src={logo} />
          <TextImg src={LogoText}/>
        </Link>
      </LogoContainer>
    </>
  );
};

export default HeaderLogo;
