// import React, { useEffect, useState, useContext } from "react";
import { useContext } from "react";

import CODAVentures from "./CODAVentures";
import OurProducts from "./OurProducts";
import ProtocolData from "./ProtocolData";
import Description from "./Description";
import OurInvestors from "./OurInvestors";
import FooterV3 from "../../components/FooterV3/Footer";

import "./common.css";
import "./index.css";

// import Slide from 'react-reveal/Slide';
// import Fade from 'react-reveal/Fade';

import { MobileContext } from '../../App'

// import { SwiperSlide } from "swiper/react/swiper-react";
// import Carousel from "./Carousel";


const Homepage = () => {
  // const [slide, setSlide] = useState(0);

  const isMobileFlag = useContext(MobileContext).isMobileFlag;

    return (
        <>
          <div className="page flex-col">
            <div className="outer1 flex-col">
              <div className="main1 flex-col">
                <CODAVentures isMobile={isMobileFlag}/>
                <OurProducts isMobile={isMobileFlag}/>
                <ProtocolData isMobile={isMobileFlag}/>
                <Description isMobile={isMobileFlag}/>
                <OurInvestors />
                <FooterV3 />
                {/* <Carousel 
                  slidesPerView={1}
                  loop={false}
                  autoPlay={false}
                  showButtons={false}
                  pagination={false}
                  direction={'vertical'}
                  onChangeSlide={setSlide}
                >
                  <SwiperSlide key="0">
                    <CODAVentures />
                  </SwiperSlide>
                  <SwiperSlide key="1">
                    <OurProducts />
                   </SwiperSlide>
                   <SwiperSlide key="2">
                    <ProtocolData />
                   </SwiperSlide>
                   <SwiperSlide key="3">
                    <Description />
                   </SwiperSlide>
                </Carousel>
                {slide >= 3 && <OurInvestors />}
                {slide >= 3 && <FooterV3 />} */}
              </div>
            </div>
          </div>
        </>
    )
}

export default Homepage;