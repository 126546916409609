// import chain_logo_bsc from './image/chain_logo_bsc.png';
// import bg1 from './image/builtforbsc_bg_1.png';
// import logo1 from './image/partners/logo1.png';
// import logo2 from './image/partners/logo2.png';
// import logo3 from './image/partners/logo3.png';
// import logo4 from './image/partners/logo4.png';
import certik from './image/partners/partners-certik.png';
import salus from './image/partners/partners-salus.png';
// import iconGitbook from './image/partners/iconGitbook.png';
import bytetrade from './image/partners/bytetrade.png';
import binance from './image/partners/binance-lab.png';
// import graph from './image/graph.png';
import "./common.css";
import "./index.css";
import Grid from '@mui/material/Grid'; // Grid version 1
import Typography from '@mui/material/Typography';

const OurInvestors = () => {
    return (
        <>
          <Grid container spacing={0} className="home-part-5" id="partners">
            <Grid item xs={12} md={6} className="left">
              <Grid className="text-center">
                <Typography variant="h5" className="head5">
                  Partners
                </Typography>
              </Grid>
              <Grid container spacing={0} columns={16} className="my-5">
                <Grid item xs={2}></Grid>
                <Grid item xs={12} className="my-auto d-flex align-items-center justify-content-around">
                    <img
                      src={bytetrade}
                      alt="investor 1"
                      style={{height: '90px'}}
                    />
                    <img
                      src={certik}
                      alt="investor 1"
                      style={{ height: '90px'}}
                    />
                    <img
                      src={salus}
                      alt="investor 1"
                      style={{ height: '90px'}}
                    />
                </Grid>
                <Grid item xs={2}></Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} className="right">
              <Grid className="text-center">
                <Typography variant="h5" className="head5">
                    Investors
                </Typography>
              </Grid>
              <Grid container spacing={0} columns={16} className="my-5">
                <Grid item xs={2}></Grid>
                <Grid item xs={12} className="text-center my-auto">
                    <img
                      src={binance}
                      alt="investor 3"
                      style={{height: "50px", marginTop: 20}}
                    />
                </Grid>
                <Grid item xs={2}></Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
    )
}

export default OurInvestors;