import { useHistory } from 'react-router-dom';
import "./common.css";
import "./index.css";
import tree from "./image/loadingIcon.gif";
// import tree from "./image/tree-rotation-1400k.mp4";
import hero from "./image/hero.svg";
import heroMobile from "./image/heroMobile.png"


// import React, { useEffect, } from "react";


import Grid from '@mui/material/Grid'; // Grid version 1
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const CODAVentures = (props) => {
  // let history = useHistory();

  const toProducts = () => {
    // history.push("products")
    window.location.replace('https://app.bonsai.money')
  }
  
  return (
      <>
        <Grid container spacing={0} columns={16} className="home-part-1"
          style={{
            backgroundImage: `url(${props.isMobile ? heroMobile : hero})`
          }}>
          <div id="page1-font" className="page1-font text pt-5">
            <Typography variant="h2" className="head1 mt-5 mb-4">
            We turn crypto volatility into <span style={{color: '#25AE7E'}}>sustainable, </span> real yield
            </Typography>
            <Typography variant="h4" className="mb-5">
              Earning yield made simple.
            </Typography>
            <Typography variant="body1" className="paragraph1 mb-5">
              Bonsai Strike capitalises on crypto volatility to create strategies with superior risk-adjusted returns. Track your deposits and yields with ease on the blockchain.
            </Typography>
            <Button variant="contained" disabled={false} size="large" color="success" onClick={toProducts} className="launch-button my-2">
              Launch App
            </Button>
          </div>
          <div id="page1-tree" className="page1-tree image">
             
            <img src={tree} className="bonsai-tree row text-center mx-auto" style={{width: '100%'}} alt="Bonsai Tree"></img>
         
            {/* <video className="bonsai-tree row text-center mx-auto pr-4 pt-4" style={{width: '100%'}} muted autoPlay loop>
              <source src={tree} type="video/mp4" />
            </video> */}
          </div>
        </Grid>
      </>
  )
}

export default CODAVentures