import React, { useCallback, useMemo } from "react";
import { useWeb3Wallet } from "shared/lib/hooks/useWeb3Wallet";
import { BigNumber, ethers } from "ethers";
// import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Col, Row } from "react-bootstrap";

import {
  BaseLink,
  SecondaryText,
  Subtitle,
  Title,
} from "shared/lib/designSystem";
import colors from "shared/lib/designSystem/colors";
import theme from "shared/lib/designSystem/theme";
import useAssetPrice from "shared/lib/hooks/useAssetPrice";
import useLoadingText from "shared/lib/hooks/useLoadingText";
import { CurrencyType } from "../../pages/Portfolio/types";
import {
  assetToUSD,
  formatBigNumber,
  isPracticallyZero,
} from "shared/lib/utils/math";
// import sizes from "shared/lib/designSystem/sizes";
import {
  getAssets,
  getDisplayAssets,
  VaultList,
  VaultNameOptionMap,
  VaultOptions,
  VaultVersion,
  VaultVersionList,
  isPutVault,
} from "shared/lib/constants/constants";
import { useAllVaultAccounts } from "shared/lib/hooks/useVaultAccounts";
import { VaultAccount } from "shared/lib/models/vault";
import {
  getAssetDecimals,
  getAssetDisplay,
  getAssetLogo,
} from "shared/lib/utils/asset";
import { getVaultColor } from "shared/lib/utils/vault";
import { getVaultURI } from "../../constants/constants";

const PortfolioPositionsContainer = styled.div`
  margin-top: 64px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const SectionTitle = styled(Title)`
  font-size: 24px;
  line-height: 24px;
  margin-right: 16px;
  width: 100%;
  padding-top:80px; 
  margin-top:-80px;
  font-family: 'MuseoModerno';
  text-transform: capitalize;
`;

const SectionPlaceholderText = styled(SecondaryText)`
  font-size: 16px;
  line-height: 24px;
  margin-top: 24px;
`;

const PositionLink = styled(BaseLink)`
  width: 100%;
  margin-top: 16px;
`;

const PositionContainer = styled.div<{ color: string }>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  border-radius: ${theme.border.radius};
  border: 2px ${theme.border.style} ${(props) => props.color}00;
  transition: 0.25s box-shadow ease-out, 0.25s border ease-out;


`;

const PositionMainContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px;
  background: #10312F;
  border: 1px solid #3F6A66;
  z-index: 2;
  &:hover {
    border: 1px solid #69ECCE;
    box-shadow: 0px 0px 20px #0A3836;
  }

`;

// const PositionStakedContainer = styled(PositionMainContainer)`
//   margin-top: -16px;
//   padding-top: calc(16px + 16px);
//   background: #10312F;
//   border: 1px solid #3F6A66;
//   z-index: 1;

// `;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  width: 40px;
  height: 40px;
  border-radius: 100px;
`;

// const PositionInfo = styled.div`
//   display: flex;
//   flex: 1;
//   flex-wrap: wrap;
//   position: relative;
// `;

// const PositionInfoRow = styled.div`
//   width: 100%;
//   display: flex;
//   margin-bottom: 4px;

//   &:last-child {
//     margin-bottom: 0px;
//   }
// `;

// const VaultVersionBadge = styled.div<{ color: string }>`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   width: 24px;
//   height: 24px;
//   border-radius: ${theme.border.radiusSmall};
//   background: ${(props) => props.color}3D;
// `;

const StyledTitle = styled(Title)`
  text-transform: none;
`;

// const PositionInfoText = styled(SecondaryText)`
//   color: ${colors.primaryText}A3;
//   font-size: 12px;
//   line-height: 16px;
//   font-weight: 400;
// `;

const PositionSecondaryInfoText = styled(Subtitle)`
  letter-spacing: unset;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #E2F8F1;
`;

// const KPIContainer = styled.div`
//   display: flex;
//   justify-content: center;
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   padding: 16px;

//   @media (max-width: ${sizes.sm}px) {
//     display: none;
//   }
// `;

// const KPIDatas = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
// `;

const KPIRoi = styled(Title)<{ variant: "red" | "green" }>`
  ${(props) => {
    switch (props.variant) {
      case "green":
        return `color: ${colors.green};`;
      case "red":
        return `color: ${colors.red};`;
    }
  }}
`;


const TradeType = styled.span`
  display: inline-block;
  padding: 8px 16px;
  background: rgba(243,186,47, 0.2);
  border-radius: 24px;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #F3BA2F;
`

interface PortfolioPositionProps {
  vaultAccount: VaultAccount;
  vaultVersion: VaultVersion;
}

const PortfolioPosition: React.FC<PortfolioPositionProps> = ({
  vaultAccount,
  vaultVersion,
}) => {
  const asset = getAssets(vaultAccount.vault.symbol);
  const decimals = getAssetDecimals(asset);
  const color = getVaultColor(vaultAccount.vault.symbol);
  // const { t } = useTranslation();
  const { price: assetPrice, loading: assetPriceLoading } = useAssetPrice({
    asset: asset,
  });
  const animatedLoadingText = useLoadingText();
  const vaultName =
    Object.keys(VaultNameOptionMap)[
      Object.values(VaultNameOptionMap).indexOf(vaultAccount.vault.symbol)
    ];

  // console.log('PortfolioPosition asset:', asset)
  // console.log('PortfolioPosition vaultAccount:')
  // console.log(vaultAccount)
  
  const renderAmountText = useCallback(
    (amount: BigNumber, currency: CurrencyType) => {
      switch (currency) {
        case "usd":
          return assetPriceLoading
            ? animatedLoadingText
            : `${assetToUSD(amount, assetPrice, decimals)}`;
        case "eth":
          return `${formatBigNumber(amount, decimals)} ${getAssetDisplay(
            asset
          )}`;
      }
    },
    [asset, assetPrice, animatedLoadingText, assetPriceLoading, decimals]
  );

  const calculatedROI = useMemo(() => {
    const netProfit = vaultAccount.totalBalance.sub(vaultAccount.totalDeposits);

    return !isPracticallyZero(vaultAccount.totalDeposits, decimals)
      ? (parseFloat(ethers.utils.formatUnits(netProfit, decimals)) /
          parseFloat(
            ethers.utils.formatUnits(vaultAccount.totalDeposits, decimals)
          )) *
          100
      : 0;
  }, [vaultAccount, decimals]);

  const logo = useMemo(() => {
    const displayAsset = getDisplayAssets(vaultAccount.vault.symbol);
    const Logo = getAssetLogo(displayAsset);

    // switch (displayAsset) {
    //   case "yvUSDC":
    //     return <Logo markerConfig={{ height: 16, width: 16 }} />;
    //   default:
        return <Logo />;
    // }
  }, [vaultAccount]);

  // const vaultBadge = useMemo(() => {
  //   switch (vaultVersion) {
  //     case "v2":
  //       return (
  //         <VaultVersionBadge color={color} className="ml-2">
  //           <Subtitle>V2</Subtitle>
  //         </VaultVersionBadge>
  //       );
  //   }

  //   return <></>;
  // }, [color, vaultVersion]);

  

  return (
    <PositionLink to={getVaultURI(vaultAccount.vault.symbol, vaultVersion)}>
      <PositionContainer color={color}>
        <PositionMainContainer>
          <Row className="d-flex align-items-center justify-content-between" style={{width: '100%'}}>
            <Col className="d-flex align-items-center justify-content-center col-md-3">
              <LogoContainer>
                {logo}
              </LogoContainer>
              <StyledTitle>{vaultName}</StyledTitle>
            </Col>

            <Col className="col-md-2 d-flex justify-content-center">
              <TradeType>{isPutVault(vaultAccount.vault.symbol) ? "PUT-SELLING" : "COVERED CALL"}</TradeType>
            </Col>

            <Col className="col-md-6 d-flex align-items-center justify-content-left">
              <Title color="#ffffff" fontSize={18} lineHeight={24}>
                Deposited:
              </Title>
              <Title className="ml-1" color="#69ECCE" fontSize={18} lineHeight={24}>
                {renderAmountText(vaultAccount.totalBalance, "eth")}
              </Title>
              <PositionSecondaryInfoText className="ml-1">
                {renderAmountText(vaultAccount.totalBalance, "usd")}
              </PositionSecondaryInfoText>
            </Col>

            <Col className="col-md-1 d-flex justify-content-center">
              <KPIRoi variant={calculatedROI >= 0 ? "green" : "red"}>
                {calculatedROI >= 0 ? "+" : ""}
                {calculatedROI.toFixed(2)}%
              </KPIRoi>
            </Col>
          </Row>

          

          {/* <LogoContainer>{logo}</LogoContainer>
          <PositionInfo>
            <PositionInfoRow>
              <StyledTitle>{vaultName}</StyledTitle>
              {vaultBadge}

              <Title className="ml-auto">
                {renderAmountText(vaultAccount.totalBalance, "eth")}
              </Title>
            </PositionInfoRow>
            <PositionInfoRow>
              <PositionInfoText>
                {t(
                  `shared:ProductCopies:${[vaultAccount.vault.symbol]}:subtitle`
                )}
              </PositionInfoText>

              <PositionSecondaryInfoText className="ml-auto">
                {renderAmountText(vaultAccount.totalBalance, "usd")}
              </PositionSecondaryInfoText>
            </PositionInfoRow>
            <KPIContainer>
              <KPIDatas>
                <KPIRoi variant={calculatedROI >= 0 ? "green" : "red"}>
                  {calculatedROI >= 0 ? "+" : ""}
                  {calculatedROI.toFixed(2)}%
                </KPIRoi>
              </KPIDatas>
            </KPIContainer>
          </PositionInfo> */}
        </PositionMainContainer>

        {/* <PositionStakedContainer>
          <PositionInfoRow>
            <PositionInfoText className="flex-grow-1">
              Staked Amount
            </PositionInfoText>
            <PositionSecondaryInfoText>
              {renderAmountText(vaultAccount.totalStakedBalance, "eth")}
            </PositionSecondaryInfoText>
          </PositionInfoRow>
        </PositionStakedContainer> */}
      </PositionContainer>
    </PositionLink>
  );
};

const PortfolioPositions = () => {
  const { active } = useWeb3Wallet();
  const {
    data: { v1: v1VaultAccounts, v2: v2VaultAccounts },
    loading,
  } = useAllVaultAccounts();
  const animatedLoadingText = useLoadingText();

  const filteredVaultAccounts = useMemo(() => {
    return Object.fromEntries(
      VaultList.map((vaultOption) => [
        vaultOption,
        Object.fromEntries(
          VaultVersionList.map((vaultVersion) => {
            switch (vaultVersion) {
              case "v1":
                return [vaultVersion, v1VaultAccounts[vaultOption]];
              case "v2":
              default:
                return [vaultVersion, v2VaultAccounts[vaultOption]];
            }
          }).filter((item) => {
            const account = item[1] as VaultAccount | undefined;

            return (
              account &&
              !isPracticallyZero(
                account.totalBalance,
                getAssetDecimals(getAssets(account.vault.symbol))
              )
            );
          })
        ),
      ]).filter((item) => Object.keys(item[1]).length > 0)
    ) as Partial<{
      [vault in VaultOptions]: Partial<{
        [version in VaultVersion]: VaultAccount;
      }>;
    }>;
  }, [v1VaultAccounts, v2VaultAccounts]);

  const positionContent = useMemo(() => {
    if (!active) {
      return <SectionPlaceholderText>---</SectionPlaceholderText>;
    }
    if (loading) {
      return (
        <SectionPlaceholderText>{animatedLoadingText}</SectionPlaceholderText>
      );
    }

    if (Object.keys(filteredVaultAccounts).length <= 0) {
      return (
        <SectionPlaceholderText>
          You have no outstanding positions
        </SectionPlaceholderText>
      );
    }

    // console.log('filteredVaultAccounts')
    // console.log(filteredVaultAccounts)

    return Object.keys(filteredVaultAccounts).map((vaultOption) =>
      Object.keys(filteredVaultAccounts[vaultOption as VaultOptions]!).map(
        (vaultVersion) => (
          <PortfolioPosition
            key={`${vaultOption}${vaultVersion}`}
            vaultAccount={
              filteredVaultAccounts[vaultOption as VaultOptions]![
                vaultVersion as VaultVersion
              ]!
            }
            vaultVersion={vaultVersion as VaultVersion}
          />
        )
      )
    );
  }, [active, animatedLoadingText, filteredVaultAccounts, loading]);

  return (
    <PortfolioPositionsContainer>
      <SectionTitle id="myPositions">My Positions</SectionTitle>
      {positionContent}
    </PortfolioPositionsContainer>
  );
};

export default PortfolioPositions;
