import { useHistory } from 'react-router-dom';
import "./common.css";
import "./index.css";
import products from "./image/products.png";
import path1 from "./image/path1.svg";
import path1Mobile from "./image/path1Mobile.png";

import Grid from '@mui/material/Grid'; // Grid version 1
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const OurProducts = (props) => {
    // let history = useHistory();

    const toProducts = () => {
      // history.push("products")
      window.location.replace('https://app.bonsai.money')
    }
    return (
        <>  
          <Grid container spacing={0} columns={16} className="home-part-2"
            style={{
              backgroundImage: `url(${props.isMobile ? path1Mobile : path1})`,
              backgroundPositionY: '2.2rem'
            }} id="products">
            <Grid xs={0} md={2}></Grid>
            <Grid xs={16} md={6} className="image">
              <img src={products} className="row text-center mx-auto" style={{width: '90%'}} alt="Our Products"></img>
            </Grid>
            <Grid xs={16} md={6} className="text">
              <Typography variant="h3" className="head1 mt-5 mb-4">
                Choose&nbsp;what's
                <br/><span style={{color: '#25AE7E'}}>
                  right&nbsp;for&nbsp;you
                </span>
              </Typography>
              <Typography variant="body1" className="paragraph1 mb-5">
                We have a series of different strategies for you to pick and choose from. Simply deposit your collateral into any combination of these strategies and let your crypto work for you.
              </Typography>
              <Button variant="contained" disabled={false} size="large" color="success" onClick={toProducts} className="launch-button my-2">
                Launch App
              </Button>
            </Grid>
     
            <Grid xs={0} md={2}></Grid>
          </Grid>
        </>
    )
}

export default OurProducts;