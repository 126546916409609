import "./footer.scss";
import logo from '../../assets/images/main-icon.svg'
// import icon2 from '../../assets/images/footer_icon_2.png'
import twitter from '../../assets/images/socials/twitter.svg'
import discord from '../../assets/images/socials/discord.svg'
import youtube from '../../assets/images/socials/youtube.svg'
import medium from '../../assets/images/socials/medium.svg'
import gitbook from '../../assets/images/socials/gitbook.svg'
import Grid from '@mui/material/Grid'; // Grid version 1
// import Typography from '@mui/material/Typography';

const Footer = () => {
    return (
      <>
        <Grid container className="pc-footer">
          <Grid item xs={12} md={5} className="rights">
            <span className="text">© 2022 BonsaiStrike. All rights reserved.</span>
          </Grid>
          <Grid item xs={12} md={2} className="text-center">
            <img
              className="logo"
              src={logo}
              alt="bonsai logo in footer"
            />
          </Grid>
          <Grid item xs={12} md={5} className="text-right socials">
            <a href="https://twitter.com/BonsaiStrike" target="_blank" rel="noreferrer">
              <img
                className="icon good"
                src={twitter}
                alt="twitter"
              />
            </a>
              <img
                className="icon"
                src={discord}
                alt="discord"
              />
              <a href="https://www.youtube.com/@BonsaiStrike" target="_blank" rel="noreferrer">
              <img
                className="icon good"
                src={youtube}
                alt="youtube"
              />
              </a>
              <img
                className="icon"
                src={medium}
                alt="medium"
              />
              <a href="https://bonsaistrike.gitbook.io/" target="_blank" rel="noreferrer">
              <img
                className="icon good"
                src={gitbook}
                alt="gitbook"
              />
              </a>
          </Grid>
        </Grid>
      </>
    )
}

export default Footer;