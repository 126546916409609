// import React, { useContext, useEffect, useState}  from "react";
import React, { useEffect, useState}  from "react";
// import axios from "axios";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
// import { Redirect } from "react-router-dom";


// import useVaultOption from "../../hooks/useVaultOption";

import PortfolioPerformance from "../../components/Portfolio/PortfolioPerformance";
import PortfolioPositions from "../../components/Portfolio/PortfolioPositions";
import PortfolioTransactions from "../../components/Portfolio/PortfolioTransactions";

import { Title } from "shared/lib/designSystem";
import StakingBanner from "../../components/Banner/StakingBanner";

// import useTransactions from "shared/lib/hooks/useTransactions";
import useVaultAccounts from "shared/lib/hooks/useVaultAccounts";
import { resolvePortfolioValueResponse } from "shared/lib/hooks/useVaultData";


const PerformanceTitle = styled(Title)`
  font-size: 24px;
  line-height: 24px;
  padding-top:80px; 
  margin-top:-80px;
  font-family: 'MuseoModerno';
  text-transform: capitalize;

`;

const NavUl = styled.ul`
  width: 100%;
  padding-left: 30px;
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
  
`

const NavLi = styled.li`
  width: 100%;
  height: 30px;
  margin-bottom: 12px;
  list-style: none;
  a {
    text-decoration-line: none;
    color: #ffffff;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    &:hover {
      color: #60D9BE;
      border-bottom: 1px solid #60D9BE;
    }
  }
`

const PortfolioCard = styled.div`
  width: 48%;
  height: 85px;
  background: rgba(105,236,206, 0.2);
  border-bottom: 2px solid #69ECCE;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 24px;

  >:first-child {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
  }

  >:last-child {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 34px;
    color: #69ECCE;
  }
`

// const RewardsCard = styled.div`
//     width: 100%;
//     height: 248px;
//     background: #0B2422;
// `

// const RewardsTitle = styled.div`
//   width: 100%;
//   line-height: 52px;
//   text-align: center;
//   background: rgba(105,236,206, 0.2);
//   font-family: 'DM Sans';
//   font-style: normal;
//   font-weight: 500;
//   font-size: 24px;
//   color: #69ECCE;

// `

// const RewardsValue = styled.div`
//   width: 48%;
//   height: 112px;
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
//   justify-content: center;
//   padding-left: 32px;

//   >:first-child {
//     font-family: 'DM Sans';
//     font-style: normal;
//     font-weight: 500;
//     font-size: 16px;
//     line-height: 21px;
//     color: #ffffff;
//   }

//   >:last-child {
//     font-family: 'DM Sans';
//     font-style: normal;
//     font-weight: 700;
//     font-size: 16px;
//     line-height: 21px;
//     color: #69ECCE;
//   }
// `

// const ClaimInput = styled.div`
//   width: 80%;
//   height: 48px;
//   background: #10312F;
//   margin: 0 auto;
//   display: flex;
//   align-items: center;
//   justify-content: around-between;
//   >:first-child {
//     width: 70%;
//     font-weight: 700;
//     font-size: 24px;
//     line-height: 48px;
//     color: #69ECCE;
//     text-align: center;
//   }

//   >:last-child {
//     width: 30%;
//     font-weight: 500;
//     font-size: 16px;
//     line-height: 48px;
//     color: #FFFFFF;
//     text-align: center;
//     background: #25AE7E;
//     cursor: pointer;
//   }

// `

const navList = [{
  name: 'Summary',
  href: '#summary'
},{
  name: 'My Positions',
  href: '#myPositions'
},{
  name: 'My Transactions',
  href: '#myTransactions'
}
// ,{
//   name: 'Leaderboard',
//   href: '#leaderboard'
// }
]

const PortfolioPage = () => {

  // const { vaultsTotalPrice } = useTransactions();
  const { vaultAccounts } = useVaultAccounts("v2");
  const [portfolioValue, setPortfolioValue] = useState(0);

  useEffect(() => {
    //USD
    // const timestamp:any = Number(new Date()).valueOf();
    // axios.get(`https://price-service.bttcdn.com/v2/asset/price?symbol=ETH,BUSD,BTC,BNB&${timestamp}`).then(res => {
    //   console.log("res_currrentPrice>", res);
    //   let data = res.data.data;
    //   let portfolioValue = resolvePortfolioValueResponse(vaultAccounts, data);
    //   setPortfolioValue(portfolioValue);
    // });

    //Token
    let portfolioValue = resolvePortfolioValueResponse(vaultAccounts, undefined);
    setPortfolioValue(portfolioValue);
  }, [vaultAccounts])

  const portfolioValueStr = portfolioValue.toFixed(4)

  // const { vaultOption = 'bETH-THETA', vaultVersion } = useVaultOption();
  // const vaultOption = "bETH-THETA";
  // const vaultVersion = "v2";

  return (
    <>
      <StakingBanner />
      <Container>
        <Row className="d-flex flex-wrap justify-content-center">
          <Col className="col-md-2">
            <NavUl>
              {
                navList.map(item => {
                  return <NavLi key={item.name}><a href={item.href}>{ item.name }</a></NavLi>
                })
              }
            </NavUl>
          </Col>

          <Col className="col-md-10" >
            <PerformanceTitle id="summary">Portfolio Summary</PerformanceTitle>
            <Row className="d-flex flex-wrap justify-content-center mt-4">
              <Col className="col-md-12 d-flex flex-column justify-content-center">
                <div className="d-flex justify-content-between">
                  <PortfolioCard>
                    <div>Portfolio Value</div>
                    <div>{portfolioValueStr} WBNB</div>
                  </PortfolioCard>
                  {/* <PortfolioCard>
                    <div>TVL</div>
                    <div>$5,365.64</div>
                  </PortfolioCard> */}
                </div>
                <PortfolioPerformance />
              </Col>

              {/* <Col className="col-md-5">
                <RewardsCard>
                  <RewardsTitle>Rewards</RewardsTitle>
                  <div className="d-flex justify-content-between">
                    <RewardsValue>
                      <div>Premiums Earned</div>
                      <div>+5.43%</div>
                    </RewardsValue>
                    <RewardsValue>
                      <div>Boosted Earnings</div>
                      <div>+12.43%</div>
                    </RewardsValue>
                  </div>

                  <ClaimInput>
                    <div>20.00 Bonsai</div>
                    <div>Claim</div>
                  </ClaimInput>
                </RewardsCard>
              </Col> */}
            </Row>



            <PortfolioPositions />
            <PortfolioTransactions />

          </Col>

        
        </Row>
      </Container>
    </>
  );
};

export default PortfolioPage;
