import React from "react";
// import { useHistory } from 'react-router-dom';
import styled from "styled-components";
import { Title, BaseButton } from "shared/lib/designSystem";
import sizes from "shared/lib/designSystem/sizes";

const LaunchAppContainer = styled.div`
  flex-wrap: wrap;
  flex-direction: column;
  cursor: pointer;
  margin-right: 3rem;
  z-index: 1;
  @media (max-width: ${sizes.md}px) {
    display: none;
  }
`;

const WalletContainer = styled.div`
  justify-content: center;
  align-items: center;

`;

const WalletButtonV2 = styled(BaseButton)`
  background-color: #25AE7E;
  align-items: center;
  height: fit-content;
  border-radius: 0;
  padding: 1rem 1.5rem;

`;
// &:hover {
//   background: #25AE7E;
// }
const WalletButtonTextConnect = styled(Title)`
  font-size: 18px;
  text-transform: unset!important;
  text-align: center !important;
  font-size: 1rem !important;
  line-height: 1rem !important;
  font-family: DM Sans !important;
  font-weight: 500 !important;
  color: rgba(255, 255, 255, 1);



`;

interface LaunchAppProps {
  showVaultPositionHook?: (show: boolean) => void;
}


const LaunchApp: React.FC<LaunchAppProps> = ({
  showVaultPositionHook,
}) => {
  // let history = useHistory();

  const toProducts = () => {
    // history.push("products")
    window.location.replace('https://app.bonsai.money')
  }

  return (
    <LaunchAppContainer>
      <WalletContainer>
        <WalletButtonV2>
        <WalletButtonTextConnect onClick={toProducts}>Launch App</WalletButtonTextConnect>
        </WalletButtonV2>
      
      </WalletContainer>

    </LaunchAppContainer>
  );
};
export default LaunchApp;
