// import React, { Component, useEffect, useState } from "react";
import { useEffect, useState } from "react";

import CODAVentures from "./CODAVentures";
import OurProducts from "./OurProducts";
import ProtocolData from "./ProtocolData";
import Description from "./Description";
import OurInvestors from "./OurInvestors";
import FooterV3 from "../../components/FooterV3/Footer";

import "./common.css";
import "./index.css";

// import Slide from 'react-reveal/Slide';
// import Fade from 'react-reveal/Fade';

import { SwiperSlide } from "swiper/react/swiper-react";
import Carousel from "./Carousel";


const Homepage = (props) => {
  const [slide, setSlide] = useState(0);

  useEffect(() => {
    setSlide(props.slidepic)
  }, [props.slidepic])

  useEffect(() => {
    setTimeout(() => {
      const page1Tree = document.getElementById("page1-tree");
      page1Tree?.classList.add('page1-tree-ani')
    }, 1000);
  
    setTimeout(() => {
      const page1Font = document.getElementById("page1-font");
      page1Font?.classList.add('page1-font-ani')
    }, 1500);
  }, [])

  const onChangeSlide = (slide) => {
    if (slide === 3) {
      setTimeout(() => {
        setSlide(slide)
        document.documentElement.scrollTop = 1;
      }, 1000);
    } else {
      setSlide(slide)
    }
  }



    return (
        <>
          <div className="page flex-col">
            <div className="outer1 flex-col">
              <div className="main1 flex-col">
                <Carousel 
                  slidePic={slide}
                  slidesPerView={1}
                  loop={false}
                  autoPlay={false}
                  showButtons={false}
                  pagination={false}
                  direction={'vertical'}
                  onChangeSlide={onChangeSlide}
                  onTransitionSwiper={props.onTransitionSwiper}
                >
                  <SwiperSlide key="0">
                    <CODAVentures />
                  </SwiperSlide>
                  <SwiperSlide key="1">
                    <OurProducts />
                   </SwiperSlide>
                   <SwiperSlide key="2">
                    <ProtocolData />
                   </SwiperSlide>
                   <SwiperSlide key="3">
                    <Description />
                   </SwiperSlide>
                </Carousel>
                {slide >= 3 && <OurInvestors />}
                {slide >= 3 && <FooterV3 />}
              </div>
            </div>
          </div>
        </>
    )
}

export default Homepage;