import React, { ReactNode, useMemo, useCallback,
  //  useState, useEffect 
  } from "react";
import { ethers } from "ethers";
// import axios from "axios";
import { useWeb3Wallet } from "shared/lib/hooks/useWeb3Wallet";
import styled from "styled-components";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { 
  // BaseLink, 
  Title } from "shared/lib/designSystem";
// import colors from "shared/lib/designSystem/colors";
import CapBar from "shared/lib/components/Deposit/CapBarRTL";
import PerformanceSection from "./PerformanceSection";
import { useVaultData, useV2VaultData, 
  // useLiquidityGaugeV5PoolData 
} from "shared/lib/hooks/web3DataContext";
import {
  formatSignificantDecimals,
  isPracticallyZero,
} from "shared/lib/utils/math";
import sizes from "shared/lib/designSystem/sizes";
import VaultActivity from "../../components/Vault/VaultActivity";
import usePullUp from "../../hooks/usePullUp";
import {
  // getVaultChain,
  // getDisplayAssets,
  // getExplorerURI,
  hasVaultVersion,
  // VaultAddressMap,
  VaultList,
  VaultOptions,
  VaultVersion,
  VaultVersionList,
  isPutVault,
} from "shared/lib/constants/constants";
import useVaultOption from "../../hooks/useVaultOption";
import { getVaultColor } from "shared/lib/utils/vault";
// import { getAssetLogo, getChainByVaultOption } from "shared/lib/utils/address";
import { Container } from "react-bootstrap";
// import theme from "shared/lib/designSystem/theme";
import { getVaultURI } from "../../constants/constants";
import DesktopActionForm from "../../components/Vault/VaultActionsForm/DesktopActionForm";
// import YourPosition from "shared/lib/components/Vault/YourPosition";
// import { truncateAddress } from "shared/lib/utils/address";
// import { ExternalIcon } from "shared/lib/assets/icons/icons";
// import useRedirectOnSwitchChain from "../../hooks/useRedirectOnSwitchChain";
import useRedirectOnWrongChain from "../../hooks/useRedirectOnWrongChain";
// import Banner from "shared/lib/components/Banner/Banner";

import useLoadingText from "shared/lib/hooks/useLoadingText";
import { formatAmount, formatAmountCapacity } from "shared/lib/utils/math";
import { getAssetDisplay } from "shared/lib/utils/asset";
// import useLatestAPY from "shared/lib/hooks/useLatestAPY";
import { useBonsaiAPR } from "shared/lib/hooks/useBonsaiAPR";
// import { calculateBaseRewards } from "shared/lib/utils/governanceMath";
// import { useAssetsPrice } from "shared/lib/hooks/useAssetPrice";
import useStrikePrice from "shared/lib/hooks/useStrikePrice";

// import TooltipExplanation from "shared/lib/components/Common/TooltipExplanation";

import IconETH from "../../assets/images/iconETH.png";
import IconBTC from "./../../assets/images/iconBTC.png";
import IconUSDC from "./../../assets/images/iconUSDC.png";
// import TipIcon from "./../../assets/images/tipIcon.png";


const { formatUnits } = ethers.utils;

const DepositPageContainer = styled(Container)`
  @media (min-width: ${sizes.xl}px) {
    max-width: 1280px;
  }

  > * {
    color: #ffffff;
    font-family: 'DM Sans';
    font-style: normal;
  }
`;

const NavUl = styled.ul`
  width: 100%;
  float: left;
  position: -webkit-sticky;
  position: sticky;
  top: 288px;
  padding: 0;
  
`

const NavLi = styled.li`
  width: 100%;
  height: 30px;
  margin-bottom: 12px;
  list-style: none;
  a {
    text-decoration-line: none;
    color: #ffffff;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    &:hover {
      color: #60D9BE;
      border-bottom: 1px solid #60D9BE;
    }
  }
`

const ContentTittle = styled.div`
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 16px;
  padding-top:288px; 
  margin-top:-288px;
`

// const ContentDescribe = styled.div`
//   font-family: 'DM Sans';
//   font-style: normal;
//   font-weight: 400;
//   font-size: 16px;
//   line-height: 28px;
//   color: #e2f8f1;
  
// `

const VaultStrategyCard = styled.div`
  background: #10312F;
  border: 1px solid #3F6A66;
  padding: 24px;
`

const StrategyDescribe = styled.div`
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #e2f8f1;
  margin-top: 16px;
  
`

const VaultInfoCard = styled.div`
  width: 100%;
  height: 285px;
  background: #10312F;
  border: 1px solid #3F6A66;
  margin-top: 16px;

  .cardHeader {
    height: 52px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 32px;
  }

  .cardMiddle {
    height: 104px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 32px;
    background: #0A3836;
    border-top: 1px solid #3F6A66;
    border-bottom: 1px solid #3F6A66;

    div > .value {
      font-weight: 700;
      font-size: 24px;
      line-height: 31px;
      margin-top: 6px;
    }
  }

  .cardFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 32px;
    margin-top: 20px;
    margin-bottom: 12px;

    div > .value {
      font-weight: 700;
      font-size: 24px;
      line-height: 31px;
      margin-top: 6px;
    }
  }
`

const Slider = styled.div<{
  percent: number;
}>`
  width: calc(100% - 64px);
  height: 4px;
  margin: 4px auto 0;
  position: relative;
  ${(props) => 
    props.percent > 80
      ? `
        background: rgba(239,45,86,0.2);
        `
      : props.percent > 50 ? `
          background: rgba(255,102,51,0.2);
        ` : `
        background: rgba(105,236,206,0.2);
      `}
  
  span {
    width: 0;
    height: 4px;
    display: inline-block;
    position: absolute;
    top:0;
    left: 0;
    ${(props) => 
      props.percent > 80
        ? `
          background: #ef2d56;
          `
        : props.percent > 50 ? `
            background: #ff6633;
          ` : `
          background: #69ecce;
        `}

  }
`

// const WeeklySnapshotCard = styled.div`
//   height: 308px;
//   background: #10312F;
//   border: 1px solid #3F6A66;

//   .cardHeader {
//     height: 74px;
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     padding: 0 24px;
//     background: #0A3836;
//     border-top: 1px solid #3F6A66;
//     border-bottom: 1px solid #3F6A66;

//     div > .value {
//       font-weight: 700;
//       font-size: 24px;
//       line-height: 31px;
//       margin-top: 6px;
//       color: #69ECCE;
//     }
//   }

//   .cardMiddle {
//     width: 100%;
//     height: 232px;
//   }
// `

// const VaultPerformanceCard = styled.div`
//   height: 394px;
//   background: #10312F;
//   border: 1px solid #3F6A66;

//   .cardHeader {
//     height: 74px;
//     display: flex;
//     justify-content: flex-end;
//     align-items: center;
//     padding: 0 24px;
//     background: #0A3836;
//     border-bottom: 1px solid #3F6A66;

//     div {
//       width: 55px;
//       height: 100%;
//       line-height: 74px;
//       font-weight: 500;
//       font-size: 16px;
//       text-align: center;
//       box-sizing: border-box;
//       cursor: pointer;
//     }
//     div.active {
//       background: rgba(105,236,206, 0.2);
//       color: rgba(105,236,206, 1);
//       border-bottom: 2px solid #69ECCE;
//     }
//   }

//   .cardMiddle {
//     width: 100%;
//     height: 232px;
    
//   }

//   .cardFooter {
//     height: 86px;
//     display: flex;
//     justify-content: space-around;
//     align-items: center;
//     background: #0A3836;
//     border-top: 1px solid #3F6A66;

//     div > .value {
//       font-weight: 700;
//       font-size: 24px;
//       line-height: 31px;
//       margin-top: 6px;
//       color: #69ECCE;
//     }
//   }

// `

const VaultOptionName = styled.div<{
  color: string;
  background: string;
}>`
  display: inline-block;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  padding: 6px 16px;
  border-radius: 32px;
  color: ${(props) => props.color};
  background-color: ${(props) => `rgba(${props.background}, 0.2)`};
`

// const AbsoluteContainer = styled.div<{ position: "top" | "bottom" }>`
//   position: absolute;
//   left: 0;
//   right: 0;
//   ${({ position }) => {
//     if (position === "top") {
//       return "top: 0;";
//     } else if (position === "bottom") {
//       return "bottom: 0;";
//     }
//     return "";
//   }}
// `;

const HeroContainer = styled.div<{ color: string }>`
  background: #10312F;
  box-shadow: 0px 16px 20px rgba(105, 236, 206, 0.1);
  padding: 24px 0;
  overflow: hidden;
`;

const HeroText = styled(Title)`
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
  margin-left: 16px;
`;

// const AttributePill = styled.div<{ color: string }>`
//   display: flex;
//   background: ${(props) => props.color}29;
//   color: ${colors.primaryText};
//   border-radius: ${theme.border.radiusSmall};
//   font-family: VCR, sans-serif;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 12px;
//   line-height: 16px;

//   text-align: center;
//   letter-spacing: 1.5px;
//   text-transform: uppercase;
// `;

// const TagPill = styled(AttributePill)`
//   padding: 16px;
// `;

// const AttributeVersionSelector = styled.div<{ color: string; active: boolean }>`
//   padding: 16px;
//   border-radius: ${theme.border.radiusSmall};
//   border: ${theme.border.width} ${theme.border.style}
//     ${(props) => (props.active ? props.color : "transparent")};
// `;

// const DesktopActionsFormContainer = styled.div`
//   display: flex;

//   @media (max-width: ${sizes.md}px) {
//     display: none;
//   }

//   @media (min-width: ${sizes.xl}px) {
//     padding: 0px 45px 0px 30px;
//   }
// `;

// const ContractButton = styled.div<{ color: string }>`
//   @media (max-width: ${sizes.md}px) {
//     display: flex;
//     justify-content: center;
//     padding: 10px 16px;
//     background: ${(props) => props.color}14;
//     border-radius: 100px;
//     margin-left: 16px;
//     margin-right: 16px;
//     margin-top: -15px;
//     margin-bottom: 48px;
//   }
//   @media (min-width: ${sizes.md + 1}px) {
//     display: none !important;
//   }
// `;

// const ContractButtonTitle = styled(Title)`
//   letter-spacing: 1px;
// `;

const navList = [{
  name: 'Vault Info',
  href: '#vaultInfo'
},{
  name: 'Vault Strategy',
  href: '#vaultStrategy'
},{
  name: 'Weekly Snapshot',
  href: '#weeklySnapshot'
},
// {
//   name: 'Vault Performance',
//   href: '#vaultPerformance'
// },
{
  name: 'Withdrawals',
  href: '#withdrawals'
},{
  name: 'Fee Structure',
  href: '#feeStructure'
},{
  name: 'Risk',
  href: '#risk'
},{
//   name: 'Staking',
//   href: '#staking'
// },{
  name: 'Vault Activity',
  href: '#vaultActivity'
}]


const DepositPage = () => {
  // const [tabsList, setTabsList] = useState([{
  //   id: 0,
  //   name: '24H',
  //   active: true,
  // },{
  //   id: 1,
  //   name: '7D',
  //   active: false,
  // },{
  //   id: 2,
  //   name: '30D',
  //   active: false,
  // },{
  //   id: 3,
  //   name: 'ALL',
  //   active: false,
  // }]);
  let { vaultOption, vaultVersion } = useVaultOption();
  const { chainId } = useWeb3Wallet();
  const loadingText = useLoadingText('Loading');

  useRedirectOnWrongChain(vaultOption, chainId);

  usePullUp();

  const { 
    status, 
    deposits, 
    vaultLimit,
  } = useVaultData(
    vaultOption || VaultList[0]
  );
  const {
    data: { asset, cap, decimals, totalBalance },
    loading,
  } = useV2VaultData(vaultOption || VaultList[0]);

  // console.log("vaultOption || VaultList[0]", vaultOption, VaultList[0], vaultVersion)

  const {
    strikePrice,
    // currentPrice,
    // isLoading: priceLoading,
    expiry,
  } = useStrikePrice(vaultOption || VaultList[0], vaultVersion);
  // const latestAPY = useLatestAPY(vaultOption || VaultList[0], vaultVersion);
  const bonsaiAPR = useBonsaiAPR();
  // const { data: lg5Data,
  //    loading: lg5DataLoading 
  //   } =
  // useLiquidityGaugeV5PoolData(vaultOption || VaultList[0]);
  // const {
  //   data: { pricePerShare },
  // } = useV2VaultData(vaultOption || VaultList[0]);
  // const { prices } = useAssetsPrice();

  // const baseAPY = useMemo(() => {
  //   if (!lg5Data) {
  //     return 0;
  //   }
  //   const rewards = calculateBaseRewards({
  //     poolSize: lg5Data.poolSize,
  //     poolReward: lg5Data.poolRewardForDuration,
  //     pricePerShare,
  //     decimals,
  //     assetPrice: prices[asset],
  //     rbnPrice: prices["RBN"],
  //   });

  //   return rewards;
  // }, [asset, decimals, lg5Data, pricePerShare, prices]);

  // const totalProjectedYield =
  // latestAPY.fetched && !lg5DataLoading
  //   ? vaultVersion === "v2"
  //     ? `${(latestAPY.res + baseAPY).toFixed(2)}%`
  //     : "0%"
  //   : '-';
  // const vaultYield = latestAPY.fetched
  //   ? vaultVersion === "v2"
  //     ? `${latestAPY.res.toFixed(2)}%`
  //     : "0%"
  //   : loadingText;
  // const baseStakingYield = !lg5DataLoading
  //   ? vaultVersion === "v2"
  //     ? `${baseAPY.toFixed(2)}%`
  //     : "0%"
  //   : loadingText;

  // useRedirectOnSwitchChain(getChainByVaultOption(vaultOption as VaultOptions));
  const isLoading = status === "loading" || loading;

  const [totalDepositStr, depositLimitStr] = useMemo(() => {
    switch (vaultVersion) {
      case "v1":
        return [
          parseFloat(
            formatSignificantDecimals(formatUnits(deposits, decimals), 2)
          ),
          parseFloat(
            formatSignificantDecimals(formatUnits(vaultLimit, decimals))
          ),
        ];
      case "v2":
        return [
          parseFloat(
            formatSignificantDecimals(formatUnits(totalBalance, decimals), 2)
          ),
          parseFloat(formatSignificantDecimals(formatUnits(cap, decimals))),
        ];
    }
  }, [cap, decimals, deposits, totalBalance, vaultLimit, vaultVersion]);


  let percent = depositLimitStr > 0 ? totalDepositStr / depositLimitStr : 0;
  if (percent < 0) {
    percent = 0;
  } else if (percent > 1) {
    percent = 1;
  }
  percent *= 100;
  const totalDepositStr_ = totalDepositStr > depositLimitStr ? depositLimitStr : totalDepositStr;

  const optionApy = useMemo(() => {
    // console.log(bonsaiAPR)
    if (vaultOption === undefined || !bonsaiAPR.fetched || bonsaiAPR.res === undefined || bonsaiAPR.res[vaultOption] === undefined) {
      return ""
    } else {
      return bonsaiAPR.res[vaultOption].times(100).toFixed(1) + "%"
    }
    
  }, [bonsaiAPR.fetched, bonsaiAPR.res, vaultOption])

  // let [optionApy, setOptionApy] = useState<string>();
  // const fn = () => {

  //   // console.log('bonsaiAPR')
  //   // console.log(bonsaiAPR)
  //   if(vaultOption !== undefined && bonsaiAPR.fetched === true && bonsaiAPR.res !== undefined && bonsaiAPR.res[vaultOption] !== undefined){
  //     setOptionApy(bonsaiAPR.res[vaultOption].times(100).toFixed(1) + "%")
  //   }
  // }

  // function useDebouncedEffect(fn, ms, deps) {
  //   useEffect(() => {
  //     // let clean = null;
  //     const timer = setTimeout(() => {
  //       // clean = fn();
  //     }, ms);
  //     return () => {
  //       clearTimeout(timer);
  //     };
  //   }, [deps, fn, ms]);
  // }

  // useDebouncedEffect(fn, 1000, [vaultOption, totalDepositStr, strikePrice]);

  const depositCapBar = (
    <CapBar
      loading={isLoading}
      current={totalDepositStr}
      cap={depositLimitStr}
      displayData={
        vaultVersion === "v1" && !isLoading && vaultLimit.isZero()
          ? { current: "---", cap: "---" }
          : undefined
      }
      copies={{
        current: "Current Vault Deposits",
        cap: "Max Vault Capacity",
      }}
      asset={asset}
      labelConfig={{
        fontSize: 18,
      }}
      statsConfig={{
        fontSize: 18,
      }}
    />
  );


  const VaultInfo = useMemo(() => {
      return (
        <div style={{marginBottom: 48}}>
          <ContentTittle id="vaultInfo">Vault Info</ContentTittle>
          {/* <ContentDescribe>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</ContentDescribe> */}
          <VaultInfoCard>
            <div className="cardHeader">
              <div>Current Cycle's Expiry:</div>
              <div>{expiry(false)}</div>
            </div>

            <div className="cardMiddle">
              <div>
                <div>APR (Gross of fee) </div>
                <div className="value">{ optionApy }{" "}</div>
              </div>
              <div>
                {/* <div>TVL:</div>
                <div className="value">$535,345.94</div> */}
              </div>
              <div>
                <div>Strike Price:</div>
                <div className="value">{vaultVersion === "v2" ? strikePrice() : "N/A"}</div>
              </div>
            </div>

            <div className="cardFooter">
              <div>
                <div>Current Deposits</div>
                <div className="value">
                  {loading
                    ? loadingText
                    : `${formatAmount(totalDepositStr_)} ${
                            asset ? getAssetDisplay(asset) : ""
                          }`
                  }
                  </div>
              </div>
              <div>
                <div>Max Capacity</div>
                <div className="value">
                  {loading
                  ? loadingText
                  : `${formatAmountCapacity(depositLimitStr)} ${
                          asset ? getAssetDisplay(asset) : ""
                        }`
                    }
                </div>
              </div>
            </div>
              
            <Slider percent={totalDepositStr_/depositLimitStr*100}>
              <span style={{width: `${totalDepositStr_/depositLimitStr*100}%`}}></span>
            </Slider>
          </VaultInfoCard>
        </div>
      );
    },
    [depositLimitStr, strikePrice, expiry, totalDepositStr_, asset, vaultVersion, loading, loadingText, optionApy]
  );
  

  const vaultText = useMemo(() => {
    let option = vaultOption
    switch (option) {
      // case "bETH-THETA":
      //   return <>
      //     <StrategyDescribe>
      //       100% of vault deposits are automatically deployed into selling call options on ETH. Strikes are determined according to our algorithm, at approximately 10-delta at the point of deployment.
      //     </StrategyDescribe>
      //     <StrategyDescribe>
      //       Premiums earned can be redeemed weekly when the vault reopens after expiry or left to auto-compound in the next deployment cycle.
      //     </StrategyDescribe>
      //   </>
      // case "bBTC-THETA":
      //   return <>
      //     <StrategyDescribe>
      //       100% of vault deposits are automatically deployed into selling call options on BTC. Strikes are determined according to our algorithm, at approximately 10-delta at the point of deployment.
      //     </StrategyDescribe>
      //     <StrategyDescribe>
      //       Premiums earned can be redeemed weekly when the vault reopens after expiry or left to auto-compound in the next deployment cycle.
      //     </StrategyDescribe>
      //   </>
      // case "bBUSD-ETH-P-THETA":
      //   return <>
      //     <StrategyDescribe>
      //       100% of vault deposits are automatically deployed into selling put options on ETH. Strikes are determined according to our algorithm, at approximately 10- delta at the point of deployment.
      //     </StrategyDescribe>
      //     <StrategyDescribe>
      //       Premiums earned can be redeemed weekly when the vault reopens after expiry or left to auto-compound in the next deployment cycle.
      //     </StrategyDescribe>
      //   </>
      case "bBNB-THETA":
        return <>
        <StrategyDescribe>
          100% of vault deposits are automatically deployed into selling call options on BNB. Strikes are determined according to our algorithm, at approximately 10-delta at the point of deployment.
        </StrategyDescribe>
        <StrategyDescribe>
          Premiums earned can be redeemed weekly when the vault reopens after expiry or left to auto-compound in the next deployment cycle.
        </StrategyDescribe>
      </>
    }

  }, [vaultOption])

  const VaultStrategy = useCallback(
    () => {
      return (
        <div style={{marginBottom: 48}}>
          <ContentTittle id="vaultStrategy">Vault Strategy</ContentTittle>
          <VaultStrategyCard>
              <VaultOptionName color={isPutVault(vaultOption || VaultList[0]) ? "#9381FF" : "#F3BA2F"} background={isPutVault(vaultOption || VaultList[0]) ? "147,129,255" : "243,186,47"}>
                {isPutVault(vaultOption || VaultList[0]) ? "Put Selling" : "Covered Call"}
              </VaultOptionName>
              {vaultText}
          </VaultStrategyCard>
        </div>
      )
    }, [vaultOption, vaultText]
  );

  const WeeklySnapshot = useMemo(() => {
    return (
      <div style={{marginBottom: 48}}>
        <ContentTittle id="weeklySnapshot">Weekly Strategy Snapshot</ContentTittle>
        {
          vaultOption && <PerformanceSection
            vault={{ vaultOption, vaultVersion }}
            active={
              !(
                vaultVersion === "v1" && isPracticallyZero(vaultLimit, decimals)
              )
            }
          />
        }
        {/* <ContentTittle id="weeklySnapshot">Weekly Strategy Snapshot</ContentTittle>
        <WeeklySnapshotCard>
          <div className="cardHeader">
            <div>
              <div>Current BNB Price</div>
              <div className="value">{loading
                    ? loadingText
                    : `${formatAmount(totalDepositStr_)} ${
                            asset ? getAssetDisplay(asset) : ""
                          }`
                  }</div>
            </div>
            <div>
              <div>BNB Strike Price</div>
              <div className="value">{vaultVersion === "v2" ? strikePrice() : "N/A"}</div>
            </div>
          </div>
          <div className="cardMiddle">
            <PerformanceSection
              vault={{ vaultOption, vaultVersion }}
              active={
                !(
                  vaultVersion === "v1" && isPracticallyZero(vaultLimit, decimals)
                )
              }
            />
          </div>
        </WeeklySnapshotCard> */}
      </div>

    )
  }, [vaultVersion, decimals, vaultLimit, vaultOption])

  // interface tabs {
  //   id: number,
  //   name: string,
  //   active: boolean
  //  }

  // const onChangeTabs = (id) => {
  //   let newArr: tabs[] = [];
  //   for (let i = 0; i < tabsList.length; i++) {
  //     const element = tabsList[i];
  //     element.active = false;
  //     if (element.id == id) {
  //       element.active = true;
  //     }
  //     newArr.push(element);
  //   }
  //   setTabsList(newArr);
  // }

  // const VaultPerformance = useMemo(() => {
  //   return (
  //     <div style={{marginBottom: 48}}>
  //       <ContentTittle id="vaultPerformance">Vault Performance</ContentTittle>
  //       <VaultPerformanceCard>
  //         <div className="cardHeader">
  //           {
  //             tabsList.map(item => {
  //               return <div className={item.active ? "active" : ""} onClick={onChangeTabs.bind(null, item.id)} key={item.id}>{ item.name }</div>
  //             })
  //           }
  //         </div>
  //         <div className="cardMiddle">
  //           <PerformanceSection
  //             vault={{ vaultOption, vaultVersion }}
  //             active={
  //               !(
  //                 vaultVersion === "v1" && isPracticallyZero(vaultLimit, decimals)
  //               )
  //             }
  //           />
  //         </div>

  //         <div className="cardFooter">
  //           <div>
  //             <div>Current BNB Price</div>
  //             <div className="value">$274.45</div>
  //           </div>
  //           <div>
  //             <div>BNB Strike Price</div>
  //             <div className="value">$352.12</div>
  //           </div>
  //         </div>
  //       </VaultPerformanceCard>
  //     </div>

  //   )
  // }, [tabsList, vaultOption, 
  // ])


  const Withdrawals = (
    <div style={{marginBottom: 48}}>
      <ContentTittle id="withdrawals">Withdrawals</ContentTittle>
      <StrategyDescribe>
        Once deployed in the strategy, withdrawals only resume after the vaults reopen after options expiry (8AM UTC). Withdrawals should be queued before expiry so that they are processed as soon as possible and to avoid getting compounded in the next vault.
      </StrategyDescribe>
    </div>
  )

  const FeeStructure = (
    <div style={{marginBottom: 48}}>
      <ContentTittle id="feeStructure">Fee Structure</ContentTittle>
      <StrategyDescribe>
        The vault charges a pro-rated management fee of 1% per annum on the notional amount and a 10% performance fee on premiums earned.
      </StrategyDescribe>
      <StrategyDescribe>
        In the event the options expire in-the-money, no fees (management or performance) will be charged.
      </StrategyDescribe>
    </div>
  )

  const Risk = (
    <div style={{marginBottom: 48}}>
      <ContentTittle id="risk">Risk   
      {/* <TooltipExplanation
        title=""
        explanation={
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut."
        }
        renderContent={({ ref, ...triggerHandler }) => (
          <img ref={ref} {...triggerHandler} width="16px" height="16px" src={TipIcon} />
        )}
      /> */}
      </ContentTittle>
      <StrategyDescribe>
        This is NOT a risk-free investment. The risks involved include, but are not confined to, smart contract risk and market movements.
      </StrategyDescribe>
      <StrategyDescribe>
        Once deployed, deposited assets are locked and you will NOT be able to cancel or redeem them before the settlement date.
      </StrategyDescribe>
      <StrategyDescribe>
        If the strike price is hit and the options are exercised, you may end up with less coins than you started with, although the total amount reflected in USD may have increased.
      </StrategyDescribe>
    </div>
  )

  // const Staking = (
  //   <div style={{marginBottom: 48}}>
  //     <ContentTittle id="staking">Staking</ContentTittle>
  //     <StrategyDescribe>
  //       After you make a deposit into the vault, you will receive LP tokens representing your deposit. You can stake the LP tokens on our platform to receive CODA tokens to maximise your yield.
  //     </StrategyDescribe>
  //   </div>
  // )


  /**
   * Redirect to homepage if no clear vault is chosen
   */
  if (!vaultOption) {
    return <Redirect to="/" />;
  }

  /**
   * Redirect to v1 if vault version given is invalid
   */
  if (!hasVaultVersion(vaultOption || VaultList[0], vaultVersion)) {
    const availableVaultVersions = VaultVersionList.filter((version) =>
      hasVaultVersion(vaultOption || VaultList[0], version)
    );

    if (availableVaultVersions.length <= 0) {
      return <Redirect to="/" />;
    }

    return (
      <Redirect
        to={getVaultURI(
          vaultOption || VaultList[0],
          availableVaultVersions[availableVaultVersions.length - 1]
        )}
      />
    );
  }

  return (
    <>
      {/* {vaultOption === "rAPE-THETA" && (
        <Banner
          message={
            "This is an experimental vault, please use at your own risk!"
          }
          color={colors.red}
        />
      )} */}

      <HeroSection
        depositCapBar={depositCapBar}
        vaultOption={vaultOption}
        variant={vaultVersion}
        v1Inactive={
          vaultVersion === "v1" ? !isLoading && vaultLimit.isZero() : undefined
        }
      />

      <DepositPageContainer className="py-5">
        <div className="row">
          <div className="col-md-2">
            <NavUl>
              {
                navList.map(item => {
                  return <NavLi key={item.name}><a href={item.href}>{ item.name }</a></NavLi>
                })
              }
            </NavUl>
          </div>

          <div className="col-md-6">
            { VaultInfo }

            <VaultStrategy />

            { WeeklySnapshot }

            {/* { VaultPerformance } */}

            { Withdrawals }

            { FeeStructure }

            { Risk }

            {/* { Staking } */}

            <VaultActivity vault={{ vaultOption, vaultVersion }} />
            
          </div>
          <div className="col-md-4" style={{height: '50%'}}>
            <DesktopActionForm vault={{ vaultOption, vaultVersion }} />
          </div>
        </div>
        {/* <div className="row">
          {VaultAddressMap[vaultOption][vaultVersion] && (
            <BaseLink
              to={`${getExplorerURI(
                getVaultChain(vaultOption)
              )}/address/${VaultAddressMap[vaultOption][vaultVersion]!}`}
              target="_blank"
              rel="noreferrer noopener"
              className="w-100"
            >
              <ContractButton color={getVaultColor(vaultOption)}>
                <ContractButtonTitle
                  fontSize={14}
                  lineHeight={20}
                  color={getVaultColor(vaultOption)}
                  className="mr-2"
                >
                  {`CONTRACT: ${truncateAddress(
                    VaultAddressMap[vaultOption][vaultVersion]!
                  )}`}
                </ContractButtonTitle>
                <ExternalIcon color={getVaultColor(vaultOption)} />
              </ContractButton>
            </BaseLink>
          )}
          <PerformanceSection
            vault={{ vaultOption, vaultVersion }}
            active={
              !(
                vaultVersion === "v1" && isPracticallyZero(vaultLimit, decimals)
              )
            }
          />

          <DesktopActionsFormContainer className="flex-column col-xl-5 offset-xl-1 col-md-6">
            <DesktopActionForm vault={{ vaultOption, vaultVersion }} />
          </DesktopActionsFormContainer>
        </div>
        <VaultActivity vault={{ vaultOption, vaultVersion }} /> */}
      </DepositPageContainer>

      {/* Desktop Position Component */}
      {/* <YourPosition vault={{ vaultOption, vaultVersion }} variant="desktop" /> */}
    </>
  );
};

const HeroSection: React.FC<{
  depositCapBar: ReactNode;
  vaultOption: VaultOptions;
  variant: VaultVersion;
  v1Inactive?: boolean;
}> = ({ depositCapBar, vaultOption, variant, v1Inactive }) => {
  const { t } = useTranslation();
  const color = getVaultColor(vaultOption);

  // const logo = useMemo(() => {
  //   const asset = getDisplayAssets(vaultOption);
  //   const Logo = getAssetLogo(asset);

  //   switch (asset) {
  //     case "WBTC":
  //     case "SOL":
  //     case "USDC":
  //       return <Logo height="200%" width="200%" />;
  //     case "AAVE":
  //     case "WAVAX":
  //       return <Logo showBackground />;
  //     case "yvUSDC":
  //       return (
  //         <Logo
  //           height="200%"
  //           width="200%"
  //           markerConfig={{
  //             right: "0px",
  //             border: "none",
  //           }}
  //         />
  //       );
  //     default:
  //       return <Logo />;
  //   }
  // }, [vaultOption]);

  const vaultIcon = {
    "bETH-THETA": (<img width="48px" height="48px" src={IconETH} alt=""/>),
    "bBTC-THETA": (<img width="48px" height="48px" src={IconBTC} alt=""/>),
    "bBUSD-ETH-P-THETA": (<img width="48px" height="48px" src={IconUSDC} alt=""/>)
  };

  return (
    <>
      {/* V1 top banner */}
      <HeroContainer color={color} style={{width: '100%', position: 'sticky', top: 80, left: 0, zIndex: 1}}>
        <DepositPageContainer className="container">
          <div className="row">
            <div style={{ zIndex: 1 }} className="col-xl-12 d-flex flex-column">
              <div className="header d-flex flex-row my-30 justify-content-between align-items-center">
                <div className="d-flex justify-content-center align-items-center" style={{fontSize: '16px', lineHeight: '20px', color: "#e2f8f1"}}>
                  <span>Vaults</span>
                  <img alt="" src="https://cdn.bttcdn.com/os/rightArrow.png" width={5} height={10} style={{margin: '0 16px'}}/>
                  <span>{t(`shared:ProductCopies:${vaultOption}:title`)}</span>
                </div>
                <VaultOptionName color={isPutVault(vaultOption) ? "#9381FF" : "#F3BA2F"} background={isPutVault(vaultOption) ? "147,129,255" : "243,186,47"}>
                  {isPutVault(vaultOption) ? "Put Selling" : "Covered Call"}
                </VaultOptionName>
                
                {/* <TagPill className="mr-2 text-uppercase" color={color}>
                  {isPutVault(vaultOption) ? "PUT-SELLING" : "COVERED CALL"}
                </TagPill>
                <AttributePill className="mr-2 text-uppercase" color={color}>
                  {[...VaultVersionList].map((version) =>
                    hasVaultVersion(vaultOption, version) ? (
                      <BaseLink
                        to={getVaultURI(vaultOption, version)}
                        key={version}
                      >
                        <AttributeVersionSelector
                          active={version === variant}
                          color={color}
                        >
                          <Title color={color}>{version}</Title>
                        </AttributeVersionSelector>
                      </BaseLink>
                    ) : null
                  )}
                </AttributePill> */}
              </div>

              <div className="d-flex justify-content-between align-items-center mt-3">
                <div className="d-flex justify-content-start align-items-center">
                  {vaultIcon[vaultOption]}
                  <HeroText>
                    {t(`shared:ProductCopies:${vaultOption}:title`)}
                  </HeroText>
                </div >

                <div style={{ zIndex: 1 }} className="col-xl-6">
                  {depositCapBar}
                </div>
              </div>

            </div>
          </div>
        </DepositPageContainer>

      </HeroContainer>
    </>
  );
};

export default DepositPage;
