import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useWeb3Wallet } from "shared/lib/hooks/useWeb3Wallet";
import { BigNumber } from "ethers";
import styled from "styled-components";
import moment from "moment";
import { AnimatePresence, motion } from "framer-motion";

import {
  BaseLink,
  SecondaryText,
  // Subtitle,
  Title,
} from "shared/lib/designSystem";
import colors from "shared/lib/designSystem/colors";
import theme from "shared/lib/designSystem/theme";
import { useAssetsPriceHistory } from "shared/lib/hooks/useAssetPrice";
import useLoadingText from "shared/lib/hooks/useLoadingText";
import useTransactions from "shared/lib/hooks/useTransactions";
import { CurrencyType } from "../../pages/Portfolio/types";
import { assetToUSD, formatBigNumber } from "shared/lib/utils/math";
import { capitalize } from "shared/lib/utils/text";
import {
  getAssets,
  getExplorerURI,
  getVaultChain,
  VaultNameOptionMap,
  VaultOptions,
} from "shared/lib/constants/constants";
import { getAssetDecimals, getAssetDisplay } from "shared/lib/utils/asset";
import { Assets } from "shared/lib/store/types";
import {
  // DepositIcon,
  ExternalIcon,
  // MigrateIcon,
  // StakeIcon,
  // TransferIcon,
  // UnstakeIcon,
  // WithdrawIcon,
} from "shared/lib/assets/icons/icons";
import { VaultTransactionType } from "shared/lib/models/vault";
import { getVaultColor } from "shared/lib/utils/vault";
import {
  PortfolioTransactionActivityFilter,
  portfolioTransactionActivityFilters,
  PortfolioTransactionSortBy,
  portfolioTransactionSortByList,
} from "./types";
import Pagination from "shared/lib/components/Common/Pagination";
import FilterDropdown from "shared/lib/components/Common/FilterDropdown";
import useScreenSize from "shared/lib/hooks/useScreenSize";
import sizes from "shared/lib/designSystem/sizes";

const PortfolioTransactionsContainer = styled.div`
  margin-top: 64px;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
`;

const SectionTitle = styled(Title)`
  font-size: 24px;
  line-height: 24px;
  margin-right: 24px;
  padding-top:80px; 
  margin-top:-80px;
  font-family: 'MuseoModerno';
  text-transform: capitalize;

  @media (max-width: ${sizes.md}px) {
    width: 100%;
    margin-bottom: 16px;
  }
`;

const TransactionTitle = styled(Title)<{ color: string }>`
  color: ${(props) => props.color};
`;

const TransactionContainer = styled.div`
  width: 100%;
  background: #0A3836;
  padding: 16px;
  display: flex;

  &:nth-child(odd){
    background: #043F3D;
  }

  &:nth-child(even){
    background: #0A3836;
  }
`;

// const TransactionTypeContainer = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   height: 40px;
//   width: 40px;
//   background: #ffffff14;
//   border-radius: 100px;
//   margin-right: 8px;
//   color: ${colors.primaryText};
//   font-size: 20px;

//   @media (max-width: ${sizes.md}px) {
//     margin: auto;
//     margin-right: 24px;
//   }
// `;

const TransactionInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
`;

const TransactionInfoRow = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 4px;

  &:last-child {
    margin-bottom: 0px;
  }
`;

const TransactionInfoText = styled(SecondaryText)<{ variant?: "dark" }>`
  color: ${colors.primaryText}A3;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;

  ${(props) => {
    switch (props.variant) {
      case "dark":
        return `color: ${colors.primaryText}3D`;
      default:
        return ``;
    }
  }}
`;

const AssetDisplayTitle = styled(Title)`
  color: ${colors.text};
  text-transform: none;
  margin-left: 8px;
`;

// const TransactionSecondaryInfoText = styled(Subtitle)`
//   color: ${colors.primaryText}A3;
//   letter-spacing: unset;
//   line-height: 16px;
// `;

const Divider = styled.div`
  border-left: 2px ${theme.border.style} #ffffff3d;
  height: auto;
  margin: 4px 16px 4px 16px;
`;

const ExternalLink = styled.div`
  width: 24px;
`;

const ExternalLinkIcon = styled(ExternalIcon)`
  opacity: ${theme.hover.opacity};

  &:hover {
    opacity: 1;
  }
`;

const TableHeader = styled.div`
  display: flex;
  margin-top: 24px;
  padding: 0px 16px;
  width: 100%;
  height: 40px;

`;

const TableHeaderCol = styled.span<{weight: number}>`
  width: ${(props) => `${props.weight}%`};
  padding: 8px 12px;
  display: inline-block;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: flex-left;
`;

const Cell = styled.span<{weight: number}>`
  width: ${(props) => `${props.weight}%`};
  padding: 8px 12px;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: flex-left;


`

const perPage = 6;

const PortfolioTransactions = () => {
  const { transactions, loading } = useTransactions();
  const { active } = useWeb3Wallet();
  // const { prices: assetPrices, loading: assetPricesLoading } = useAssetsPrice();
  const { searchAssetPriceFromTimestamp, loading: assetPricesLoading } =
    useAssetsPriceHistory();
  const animatedLoadingText = useLoadingText();
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState<PortfolioTransactionSortBy>(
    portfolioTransactionSortByList[0]
  );
  const [activityFilter, setActivityFilter] =
    useState<PortfolioTransactionActivityFilter>(
      portfolioTransactionActivityFilters[0]
    );
  const { width } = useScreenSize();

  const processedTransactions = useMemo(() => {
    let filteredTransactions = transactions;

    /** Filter transactions */
    switch (activityFilter) {
      case "all activity":
        break;
      case "instant withdraw":
        filteredTransactions = transactions.filter(
          (transaction) => transaction.type === "instantWithdraw"
        );
        break;
      default:
        filteredTransactions = transactions.filter(
          (transaction) => transaction.type === activityFilter
        );
    }

    /** Sort */
    switch (sortBy) {
      case "latest first":
        filteredTransactions.sort((a, b) =>
          a.timestamp < b.timestamp ? 1 : -1
        );
        break;
      case "oldest first":
        filteredTransactions.sort((a, b) =>
          a.timestamp > b.timestamp ? 1 : -1
        );
        break;
    }

    return filteredTransactions;
  }, [activityFilter, sortBy, transactions]);

  useEffect(() => {
    const totalNumPage = Math.ceil(processedTransactions.length / perPage);
    if (
      processedTransactions.length &&
      page > Math.ceil(processedTransactions.length / perPage)
    ) {
      setPage(totalNumPage);
    }
  }, [page, processedTransactions]);

  const getTransactionAssetText = useCallback(
    (vaultOption: VaultOptions, type: VaultTransactionType) => {
      switch (type) {
        case "stake":
        case "unstake":
          return vaultOption;
        default:
          return getAssetDisplay(getAssets(vaultOption));
      }
    },
    []
  );

  const renderTransactionAmountText = useCallback(
    (
      amount: BigNumber,
      type: VaultTransactionType,
      currency: CurrencyType,
      asset: Assets,
      // In seconds
      timestamp: number
    ) => {
      let prependSymbol = "";
      switch (type) {
        case "deposit":
        case "receive":
        case "stake":
          prependSymbol = "+";
          break;
        case "withdraw":
        case "initiateWithdraw":
        case "instantWithdraw":
        case "transfer":
        case "unstake":
          prependSymbol = "-";
      }

      switch (currency) {
        case "usd":
          return assetPricesLoading
            ? animatedLoadingText
            : 
            (
              <span style={{color: `${prependSymbol === "+" ? "#69ECCE" : "#EF2D56"}`}}>
                {prependSymbol}{assetToUSD(
                  amount,
                  searchAssetPriceFromTimestamp(asset, timestamp * 1000),
                  getAssetDecimals(asset)
                )}
              </span>
            );
        case "eth":
          return (
            <span style={{color: `${prependSymbol === "+" ? "#69ECCE" : "#EF2D56"}`}}>
              {prependSymbol}{formatBigNumber(
                amount,
                getAssetDecimals(asset)
              )}
            </span>
          );
      }
    },
    [assetPricesLoading, animatedLoadingText, searchAssetPriceFromTimestamp]
  );

  // const renderTransactionSymbol = useCallback((type: VaultTransactionType) => {
  //   switch (type) {
  //     case "deposit":
  //     case "receive":
  //       return <DepositIcon width={20} />;
  //     case "withdraw":
  //     case "initiateWithdraw":
  //     case "instantWithdraw":
  //       return <WithdrawIcon width={20} />;
  //     case "stake":
  //       return <StakeIcon />;
  //     case "unstake":
  //       return <UnstakeIcon />;
  //     case "migrate":
  //       return <MigrateIcon width={14} height={14} />;
  //     case "transfer":
  //       return <TransferIcon width={14} height={14} />;
  //   }
  // }, []);

  const renderStrategy = useCallback((symbol) => {
    switch (symbol) {
      case 'bETH-THETA':
      case 'bBTC-THETA':
      case 'bBNB-THETA':
        return 'COVERED CALL';

      case 'bBUSD-ETH-P-THETA':
      case 'rBUSD-AVAX-P-THETA':
        return 'PUT-SELLING';
    }

  }, [])

  const getTransactionTypeDisplay = useCallback(
    (type: VaultTransactionType) => {
      switch (type) {
        case "instantWithdraw":
          return "Instant Withdraw";
        case "initiateWithdraw":
          return "Initiate Withdraw";
        default:
          return capitalize(type);
      }
    },
    []
  );

  const renderTransactions = useCallback(() => {
    if (!active) {
      return (
        <SecondaryText fontSize={16} lineHeight={24}>
          ---
        </SecondaryText>
      );
    }

    if (loading) {
      return (
        <SecondaryText fontSize={16} lineHeight={24}>
          {animatedLoadingText}
        </SecondaryText>
      );
    }

    if (processedTransactions.length <= 0) {
      return (
        <SecondaryText fontSize={16} lineHeight={24}>
          You have no transactions
        </SecondaryText>
      );
    }
  
    return processedTransactions
      .slice((page - 1) * perPage, page * perPage)
      .map((transaction, index) => (
        <TransactionContainer
          key={transaction.id}
          className="align-items-md-center"
        >
          <Cell weight={20}>
            {transaction.vault.symbol}
          </Cell>
          <Cell weight={15}>
            {renderStrategy(transaction.vault.symbol)}
          </Cell>
          <Cell weight={15}>
            {transaction.type}
          </Cell>
          <Cell weight={15}>
            {renderTransactionAmountText(
              transaction.amount,
              transaction.type,
              "eth",
              getAssets(transaction.vault.symbol),
              transaction.timestamp
            )}
            <AssetDisplayTitle>
              {getTransactionAssetText(
                transaction.vault.symbol,
                transaction.type
              )}
            </AssetDisplayTitle>
          </Cell>
          <Cell weight={15}>
              {renderTransactionAmountText(
                transaction.underlyingAmount,
                transaction.type,
                "usd",
                getAssets(transaction.vault.symbol),
                transaction.timestamp
              )
            }
          </Cell>
          <Cell weight={15}>
            {moment(transaction.timestamp, "X").format("DD/MM/YY")}
          </Cell>
          <Cell weight={5}>
            <BaseLink
                to={`${getExplorerURI(
                  getVaultChain(transaction.vault.symbol)
                )}/tx/${transaction.txhash}`}
                target="_blank"
                rel="noreferrer noopener"
                className="d-none d-md-block"
              >
                <ExternalLink>
                  <ExternalLinkIcon color="white" />
                </ExternalLink>
              </BaseLink>
          </Cell>
          
          {/* <TransactionTypeContainer>
            {renderTransactionSymbol(transaction.type)}
          </TransactionTypeContainer> */}

          {/* Desktop */}
          {/* <TransactionInfo className="d-none d-md-flex">
            <TransactionInfoRow>
              <TransactionTitle
                color={getVaultColor(transaction.vault.symbol)}
                className="mr-auto"
              >
                {
                  Object.keys(VaultNameOptionMap)[
                    Object.values(VaultNameOptionMap).indexOf(
                      transaction.vault.symbol as VaultOptions
                    )
                  ]
                }
              </TransactionTitle>

              <Title>
                {renderTransactionAmountText(
                  transaction.amount,
                  transaction.type,
                  "eth",
                  getAssets(transaction.vault.symbol),
                  transaction.timestamp
                )}
              </Title>
              <AssetDisplayTitle>
                {getTransactionAssetText(
                  transaction.vault.symbol,
                  transaction.type
                )}
              </AssetDisplayTitle>
            </TransactionInfoRow>
            <TransactionInfoRow>
              <TransactionInfoText className="mr-auto">
                {`${getTransactionTypeDisplay(transaction.type)} - ${moment(
                  transaction.timestamp,
                  "X"
                ).fromNow()}`}
              </TransactionInfoText>

              <TransactionSecondaryInfoText>
                {renderTransactionAmountText(
                  transaction.underlyingAmount,
                  transaction.type,
                  "usd",
                  getAssets(transaction.vault.symbol),
                  transaction.timestamp
                )}
              </TransactionSecondaryInfoText>
            </TransactionInfoRow>
          </TransactionInfo> */}

          {/* Mobile */}
          <TransactionInfo className="d-flex d-md-none">
            <TransactionInfoRow>
              {/* Title */}
              <TransactionTitle color={getVaultColor(transaction.vault.symbol)}>
                {
                  Object.keys(VaultNameOptionMap)[
                    Object.values(VaultNameOptionMap).indexOf(
                      transaction.vault.symbol as VaultOptions
                    )
                  ]
                }
              </TransactionTitle>

              <Divider />

              {/* Type */}
              <Title>{getTransactionTypeDisplay(transaction.type)}</Title>
            </TransactionInfoRow>
            <TransactionInfoRow>
              {/* Amount in crypto */}
              <TransactionInfoText className="mr-1">
                {renderTransactionAmountText(
                  transaction.amount,
                  transaction.type,
                  "eth",
                  getAssets(transaction.vault.symbol),
                  transaction.timestamp
                )}
              </TransactionInfoText>
              <TransactionInfoText variant="dark">
                {getTransactionAssetText(
                  transaction.vault.symbol,
                  transaction.type
                )}
              </TransactionInfoText>
            </TransactionInfoRow>
            <div className="pt-2">
              {/* Type and Time */}
              <TransactionInfoText className="mr-auto">
                {moment(transaction.timestamp, "X").fromNow()}
              </TransactionInfoText>
            </div>
          </TransactionInfo>

          {/* {
            <BaseLink
              to={`${getExplorerURI(
                getVaultChain(transaction.vault.symbol)
              )}/tx/${transaction.txhash}`}
              target="_blank"
              rel="noreferrer noopener"
              className="d-none d-md-block"
            >
              <ExternalLink>
                <ExternalLinkIcon color="white" />
              </ExternalLink>
            </BaseLink>
          } */}
        </TransactionContainer>
      ));
  }, [
    renderStrategy,
    active,
    getTransactionTypeDisplay,
    page,
    processedTransactions,
    animatedLoadingText,
    loading,
    // renderTransactionSymbol,
    renderTransactionAmountText,
    getTransactionAssetText,
  ]);


  const headerData = [{
    id: 0,
    weight: 20,
    labels: "Action",
  },{
    id: 1,
    weight: 15,
    labels: "Strategy",
  },{
    id: 2,
    weight: 15,
    labels: "Type",
  },{
    id: 3,
    weight: 30,
    labels: "Amount",
  },{
    id: 4,
    weight: 15,
    labels: "Transaction Date",
  },{
    id: 5,
    weight: 5,
    labels: "",
  }]

  return (
    <PortfolioTransactionsContainer className="mb-5">
      <div className="d-flex flex-wrap align-items-center justify-content-between mb-4" style={{width: '100%'}}>
        <SectionTitle id="myTransactions">Transaction History</SectionTitle>
        <div className="d-flex align-items-center justify-content-center" style={{width: 438}}>
          <FilterDropdown
            // @ts-ignore
            options={portfolioTransactionActivityFilters}
            value={activityFilter}
            // @ts-ignore
            onSelect={setActivityFilter}
            dropdownMenuConfig={{
              horizontalOrientation: width > sizes.md ? "right" : "left",
              topBuffer: 8,
            }}
          />
          <FilterDropdown
            // @ts-ignore
            options={portfolioTransactionSortByList}
            value={sortBy}
            // @ts-ignore
            onSelect={setSortBy}
            className="ml-3"
          />
        </div>
      </div>
      <TableHeader>
        {headerData.map((item, index) => (
          <TableHeaderCol
            key={index}
            weight={item.weight}
          >
            <SecondaryText color="#ffffff">{item.labels}</SecondaryText>
          </TableHeaderCol>
        ))}
      </TableHeader>
      <AnimatePresence initial={false} exitBeforeEnter>
        <motion.div
          key={page}
          transition={{
            duration: 0.25,
            type: "keyframes",
            ease: "easeInOut",
          }}
          initial={{
            y: 50,
            opacity: 0,
          }}
          animate={{
            y: 0,
            opacity: 1,
          }}
          exit={{
            y: 50,
            opacity: 0,
          }}
          className="w-100"
          style={{border: `${loading ? "0" : '1px solid rgba(226,248,241, 0.5)'}`, marginBottom: 40}}
        >
          {renderTransactions()}
        </motion.div>
      </AnimatePresence>
      <Pagination
        page={page}
        total={Math.ceil(processedTransactions.length / perPage)}
        setPage={setPage}
      />
    </PortfolioTransactionsContainer>
  );
};

export default PortfolioTransactions;
