import React, { useCallback, useMemo, 
  // useState, useEffect 
} from "react";
import styled from "styled-components";
import { BigNumber } from "ethers";
import { useTranslation } from "react-i18next";
// import axios from "axios";

import { SecondaryText, Title, PrimaryText } from "shared/lib/designSystem";
import { ActionButton } from "shared/lib/components/Common/buttons";
import { ACTIONS, ActionType, V2WithdrawOption } from "./types";
import { formatBigNumber } from "shared/lib/utils/math";
import { getAssetDecimals, getAssetDisplay } from "shared/lib/utils/asset";
import { Assets } from "shared/lib/store/types";
import {
  VaultOptions,
  // VaultFees,
  VaultVersion,
  // isPutVault,
} from "shared/lib/constants/constants";
import { getVaultColor } from "shared/lib/utils/vault";
import { capitalize } from "shared/lib/utils/text";
import {
  // DepositIcon,
  MigrateIcon,
  // TransferIcon,
  WithdrawIcon,
} from "shared/lib/assets/icons/icons";
// import colors from "shared/lib/designSystem/colors";
import theme from "shared/lib/designSystem/theme";
import { useVaultData, useV2VaultData, 
  // useLiquidityGaugeV5PoolData 
} from "shared/lib/hooks/web3DataContext";
// import useLatestAPY from "shared/lib/hooks/useLatestAPY";
import { useBonsaiAPR } from "shared/lib/hooks/useBonsaiAPR";
import useVaultPriceHistory from "shared/lib/hooks/useVaultPerformanceUpdate";
import { parseUnits, formatUnits } from "ethers/lib/utils";

import {
  formatSignificantDecimals,
} from "shared/lib/utils/math";
import useLoadingText from "shared/lib/hooks/useLoadingText";
// import { formatAmount } from "shared/lib/utils/math";
// import { calculateBaseRewards } from "shared/lib/utils/governanceMath";
// import { useAssetsPrice } from "shared/lib/hooks/useAssetPrice";
import useStrikePrice from "shared/lib/hooks/useStrikePrice";


const ActionLogoContainer = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border-radius: 100px;
  background: ${(props) => props.color}14;
`;

const DeposilTitle = styled.div`
  width: calc(100% + 32px);
  height: 102px;
  line-height: 102px;
  text-align: center;
  background: #10312F;
  font-size: 24px;
  font-family: 'MuseoModerno';
  font-style: normal;
  color: #ffffff;
`

const DeposilText = styled.div`
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #E2F8F1;
`

const DeposilValue = styled.div`
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #69ECCE;
`

const FormTitle = styled(Title)`
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 1px;
  font-family: 'MuseoModerno';
  font-style: normal;
`;

const Arrow = styled.i<{ color: string }>`
  font-size: 12px;
  color: ${(props) => props.color};
`;

const WithdrawalStepsCircle = styled.div<{ active: boolean; color: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  border-radius: 32px;
  background: ${(props) => props.color}14;

  ${(props) =>
    props.active
      ? `
          border: ${theme.border.width} ${theme.border.style} ${props.color};
        `
      : ``}
`;

const WithdrawalStepsDividerLine = styled.div<{ color: string }>`
  width: 40px;
  height: 1px;
  background: ${(props) => props.color}3D;
  margin-top: calc((32px - 1px) / 2);
`;

const WarningContainer = styled.div<{ color: string }>`
  padding: 8px;
  background: ${(props) => props.color}14;
  border-radius: ${theme.border.radiusSmall};
`;

const CommitButon = styled.button`
  width: 100%;
  background: #25AE7E;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #FFFFFF; 
  text-align: center;
  border: 0;

  &:hover{ 
    color: #FFFFFF; 
  }
`

const PreviewStep: React.FC<{
  actionType: ActionType;
  withdrawOption?: V2WithdrawOption;
  amount: BigNumber;
  positionAmount: BigNumber;
  onClickConfirmButton: () => Promise<void>;
  asset: Assets;
  vaultOption: VaultOptions;
  vaultVersion: VaultVersion;
  receiveVaultOption?: VaultOptions;
}> = ({
  actionType,
  withdrawOption,
  amount,
  positionAmount,
  onClickConfirmButton,
  asset,
  vaultOption,
  vaultVersion,
  receiveVaultOption,
}) => {
  const { t } = useTranslation();
  const color = getVaultColor(vaultOption);
  // const latestAPY = useLatestAPY(vaultOption, vaultVersion);
  const bonsaiAPR = useBonsaiAPR();
  const {
    data: { 
      withdrawals: v2Withdrawals ,
      // depositBalanceInAsset: v2DepositBalanceInAsset,
      lockedBalanceInAsset: v2LockedBalanceInAsset,
    },
  } = useV2VaultData(vaultOption);
  const { priceHistory } = useVaultPriceHistory(vaultOption, vaultVersion);

  const optionApy = useMemo(() => {

    if (vaultOption === undefined || !bonsaiAPR.fetched || bonsaiAPR.res === undefined || bonsaiAPR.res[vaultOption] === undefined) {
      return ""
    } else {
      return bonsaiAPR.res[vaultOption].times(100).toFixed(1) + "%"
    }
    
  }, [bonsaiAPR.fetched, bonsaiAPR.res, vaultOption])

  // let [optionApy, setOptionApy] = useState<string>();
  // const fn = () => {
  //   // console.log('bonsaiAPR')
  //   // console.log(bonsaiAPR)
  //   if(bonsaiAPR.fetched === true && bonsaiAPR.res !== undefined && bonsaiAPR.res[vaultOption] !== undefined){
  //     setOptionApy(bonsaiAPR.res[vaultOption].times(100).toFixed(1) + "%")
  //   }
  // }
  // function useDebouncedEffect(fn, ms, deps) {
  //   useEffect(() => {
  //     // let clean = null;
  //     const timer = setTimeout(() => {
  //       // clean = fn();
  //     }, ms);
  //     return () => {
  //       clearTimeout(timer);
  //     };
  //   }, [deps, fn, ms]);
  // }

  // useDebouncedEffect(fn, 1000, [vaultOption]);

  const loadingText = useLoadingText('Loading');
  const { 
    // status, 
    deposits, 
    vaultLimit,
  } = useVaultData(
    vaultOption
  );
  const {
    data: { cap, decimals, totalBalance },
    loading,
  } = useV2VaultData(vaultOption);

  const {
    // strikePrice,
    // currentPrice,
    // isLoading: priceLoading,
    expiry,
  } = useStrikePrice(vaultOption, vaultVersion);
  // const { data: lg5Data, 
  //   loading: lg5DataLoading 
  // } =
  // useLiquidityGaugeV5PoolData(vaultOption);
  // const {
  //   data: { pricePerShare },
  // } = useV2VaultData(vaultOption);
  // const { prices } = useAssetsPrice();

  // const baseAPY = useMemo(() => {
  //   if (!lg5Data) {
  //     return 0;
  //   }
  //   const rewards = calculateBaseRewards({
  //     poolSize: lg5Data.poolSize,
  //     poolReward: lg5Data.poolRewardForDuration,
  //     pricePerShare,
  //     decimals,
  //     assetPrice: prices[asset],
  //     rbnPrice: prices["RBN"],
  //   });

  //   return rewards;
  // }, [asset, decimals, lg5Data, pricePerShare, prices]);
  

  // const totalProjectedYield =
  //   latestAPY.fetched && !lg5DataLoading
  //     ? vaultVersion === "v2"
  //       ? `${(latestAPY.res + baseAPY).toFixed(2)}%`
  //       : "0%"
  //     : loadingText;
  // const vaultYield = latestAPY.fetched
  //   ? vaultVersion === "v2"
  //     ? `${latestAPY.res.toFixed(2)}%`
  //     : "0%"
  //   : loadingText;
  // const baseStakingYield = !lg5DataLoading
  //   ? vaultVersion === "v2"
  //     ? `${baseAPY.toFixed(2)}%`
  //     : "0%"
  //   : loadingText;

  const [totalDepositStr, depositLimitStr] = useMemo(() => {
    switch (vaultVersion) {
      case "v1":
        return [
          parseFloat(
            formatSignificantDecimals(formatUnits(deposits, decimals), 2)
          ),
          parseFloat(
            formatSignificantDecimals(formatUnits(vaultLimit, decimals))
          ),
        ];
      case "v2":
        return [
          parseFloat(
            formatSignificantDecimals(formatUnits(totalBalance, decimals), 2)
          ),
          parseFloat(formatSignificantDecimals(formatUnits(cap, decimals))),
        ];
    }
  }, [cap, decimals, deposits, totalBalance, vaultLimit, vaultVersion]);

  let percent = depositLimitStr > 0 ? totalDepositStr / depositLimitStr : 0;
  if (percent < 0) {
    percent = 0;
  } else if (percent > 1) {
    percent = 1;
  }
  percent *= 100;
  // const totalDepositStr_ = totalDepositStr > depositLimitStr ? depositLimitStr : totalDepositStr;



  // interface ActionDetail {
  //   key: string;
  //   value: string;
  // }
  // const detailRows: ActionDetail[] = useMemo(() => {
  //   const actionDetails: ActionDetail[] = [];

  //   switch (actionType) {
  //     case ACTIONS.deposit:
  //       actionDetails.push({
  //         key: "Approx. APY",
  //         value: `${
  //           latestAPY.fetched ? latestAPY.res.toFixed(2) : "0.00"
  //         }% APY`,
  //       });
  //       break;
  //     case ACTIONS.withdraw:
  //       switch (vaultVersion) {
  //         case "v1":
  //           actionDetails.push({
  //             key: "Withdrawal Fee",
  //             value: `${parseFloat(
  //               VaultFees[vaultOption].v1?.withdrawalFee ?? "0"
  //             ).toString()}%`,
  //           });
  //           break;
  //         case "v2":
  //           /**
  //            * Strategy are only shown in instant withdraw.
  //            */
  //           switch (withdrawOption) {
  //             case "instant":
  //               switch (vaultOption) {
  //                 case "rstETH-THETA":
  //                   actionDetails.push({
  //                     key: "Max Slippage",
  //                     value: "0.3%",
  //                   });
  //                   break;
  //                 default:
  //                   actionDetails.push({
  //                     key: "Strategy",
  //                     value: isPutVault(vaultOption)
  //                       ? "PUT SELLING"
  //                       : "COVERED CALL",
  //                   });
  //               }
  //           }
  //       }
  //       break;
  //     case ACTIONS.transfer:
  //       actionDetails.push(
  //         {
  //           key: "Transfer To",
  //           value: t(`shared:ProductCopies:${receiveVaultOption!}:title`),
  //         },
  //         {
  //           key: "Transfer From",
  //           value: t(`shared:ProductCopies:${vaultOption}:title`),
  //         },
  //         {
  //           key: "Transfer Fee",
  //           value: "0.00%",
  //         }
  //       );
  //   }

  //   const details: ActionDetail[] = [
  //     { key: "Product", value: t(`shared:ProductCopies:${vaultOption}:title`) },
  //     ...actionDetails,
  //   ];
  //   return details;
  // }, [
  //   actionType,
  //   latestAPY,
  //   receiveVaultOption,
  //   t,
  //   vaultOption,
  //   vaultVersion,
  //   withdrawOption,
  // ]);

  const originalAmount = formatBigNumber(
    positionAmount,
    getAssetDecimals(asset)
  );

  const renderWithdrawalSteps = useCallback(
    (withdrawOption: V2WithdrawOption) => {
      if (withdrawOption === "instant") {
        return <></>;
      }

      return (
        <div className="d-flex mt-2">
          <div className="d-flex flex-column align-items-center">
            <WithdrawalStepsCircle
              active={withdrawOption === "standard"}
              color={color}
            >
              <Title
                fontSize={14}
                lineHeight={20}
                color={
                  withdrawOption === "standard" ? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, 0.7)"
                }
              >
                1
              </Title>
            </WithdrawalStepsCircle>
            <PrimaryText
              fontSize={11}
              lineHeight={12}
              color={
                withdrawOption === "standard" ? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, 0.7)"
              }
              className="mt-2 text-center"
              style={{ maxWidth: 60 }}
            >
              Initiate Withdrawal
            </PrimaryText>
          </div>
          <WithdrawalStepsDividerLine color={color} />
          <div className="d-flex flex-column align-items-center">
            <WithdrawalStepsCircle
              active={withdrawOption === "complete"}
              color={color}
            >
              <Title
                fontSize={14}
                lineHeight={20}
                color={
                  withdrawOption === "complete" ? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, 1)"
                }
              >
                2
              </Title>
            </WithdrawalStepsCircle>
            <PrimaryText
              fontSize={11}
              lineHeight={12}
              color={
                withdrawOption === "complete" ? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, 1)"
              }
              className="mt-2 text-center mb-4"
              style={{ maxWidth: 60 }}
            >
              Complete Withdrawal
            </PrimaryText>
          </div>
        </div>
      );
    },
    [color]
  );

  switch (actionType) {
    case ACTIONS.migrate:
      return (
        <div
          style={{ flex: 1 }}
          className="d-flex flex-column align-items-center"
        >
          {/* Logo */}
          <ActionLogoContainer color={color}>
            <MigrateIcon color={color} height={27} />
          </ActionLogoContainer>

          {/* Title */}
          <FormTitle className="mt-3">MIGRATE PREVIEW</FormTitle>

          {/* Info Preview */}
          <div className="d-flex w-100 flex-row align-items-center justify-content-between mt-4 mt-lg-auto">
            <SecondaryText>Amount</SecondaryText>
            <Title className="text-right">
              {originalAmount} {getAssetDisplay(asset)}
            </Title>
          </div>
          <div className="d-flex w-100 flex-row align-items-center justify-content-between mt-4">
            <SecondaryText>Product</SecondaryText>
            <Title className="text-right">
              {t(`shared:ProductCopies:${vaultOption}:title`)}
            </Title>
          </div>
          <div className="d-flex w-100 flex-row align-items-center justify-content-between mt-4 mb-4 mb-md-5 mb-lg-auto">
            <SecondaryText>Migration</SecondaryText>
            <Title className="d-flex align-items-center text-right">
              V1 <Arrow className="fas fa-arrow-right mx-2" color={color} /> V2
            </Title>
          </div>

          {/* Migrate Button */}
          <ActionButton
            onClick={onClickConfirmButton}
            className="btn py-3 mb-4"
            color={color}
          >
            CONFIRM MIGRATION TO V2
          </ActionButton>
        </div>
      );
    // @ts-ignore
    case ACTIONS.withdraw:
      switch (withdrawOption) {
        case "standard":
          return (
            <div className="d-flex flex-column align-items-center">
              {/* Title */}
              <FormTitle className="mt-4 mx-3 text-center">
                INITIATE WITHDRAWAL PREVIEW
              </FormTitle>

              {/* Info Preview */}
              <div className="d-flex w-100 flex-row align-items-center justify-content-between mt-4">
                <SecondaryText>Amount</SecondaryText>
                <Title className="text-right">
                  {formatBigNumber(amount, getAssetDecimals(asset))}{" "}
                  {getAssetDisplay(asset)}
                </Title>
              </div>
              <div className="d-flex w-100 flex-row align-items-center justify-content-between mt-4">
                <SecondaryText>Already Initiated</SecondaryText>
                <Title className="text-right">
                  {formatBigNumber(
                    v2Withdrawals.shares
                      .mul(
                        priceHistory.find(
                          (history) => history.round === v2Withdrawals.round
                        )?.pricePerShare || BigNumber.from(0)
                      )
                      .div(parseUnits("1", getAssetDecimals(asset))),
                    getAssetDecimals(asset)
                  )}{" "}
                  {getAssetDisplay(asset)}
                </Title>
              </div>
              <div className="d-flex w-100 flex-row align-items-center justify-content-between mt-4">
                <SecondaryText>Product</SecondaryText>
                <Title className="text-right">
                  {t(`shared:ProductCopies:${vaultOption}:title`)}
                </Title>
              </div>

              {/* Migrate Button */}
              <ActionButton
                onClick={onClickConfirmButton}
                className="btn py-3 mt-4 mb-3"
                color={color}
              >
                INITIATE WITHDRAWAL
              </ActionButton>
              {renderWithdrawalSteps("standard")}
              <WarningContainer
                className="mb-4 w-100 text-center"
                color={color}
              >
                <PrimaryText fontSize={14} lineHeight={20} color={color}>
                  {`You can complete your withdrawal any time after 12pm UTC on
                  Friday when your ${getAssetDisplay(
                    asset
                  )} will be removed from the vault’s
                  investable pool of funds`}
                </PrimaryText>
              </WarningContainer>
            </div>
          );
        case "complete":
          return (
            <div className="d-flex flex-column align-items-center">
              {/* Logo */}
              <ActionLogoContainer color={color}>
                <WithdrawIcon color={color} width={32} />
              </ActionLogoContainer>

              {/* Title */}
              <FormTitle className="mt-3 text-center">
                COMPLETE WITHDRAWAL
              </FormTitle>

              {/* Info Preview */}
              {/* {vaultOption === "rstETH-THETA" ? (
                <div className="d-flex w-100 flex-row align-items-center justify-content-between mt-4">
                  <SecondaryText>Estimated Withdraw Amount</SecondaryText>
                  <Title className="text-right">
                    {formatBigNumber(amount, getAssetDecimals(asset))}{" "}
                    {getAssetDisplay(asset)}
                  </Title>
                </div>
              ) : ( */}
                <div className="d-flex w-100 flex-row align-items-center justify-content-between mt-4">
                  <SecondaryText>Withdraw Amount</SecondaryText>
                  <Title className="text-right">
                    {formatBigNumber(amount, getAssetDecimals(asset))}{" "}
                    {getAssetDisplay(asset)}
                  </Title>
                </div>
              {/*)}*/}
              <div className="d-flex w-100 flex-row align-items-center justify-content-between mt-4">
                <SecondaryText>Product</SecondaryText>
                <Title className="text-right">
                  {t(`shared:ProductCopies:${vaultOption}:title`)}
                </Title>
              </div>

              {/* Migrate Button */}
              <ActionButton
                onClick={onClickConfirmButton}
                className="btn py-3 mt-4 mb-3"
                color="#ffffff"
              >
                COMPLETE WITHDRAWAL
              </ActionButton>
              {renderWithdrawalSteps("complete")}
            </div>
          );
      }

    /**
     * IMPORTANT
     * Do note over here that the fallthrough case currently will only allow V1 withdraw and v2 instant withdraw to fall through.
     * Exercise great caution when introducing further fallthrough to avoid unintended consequences.
     */
    // eslint-disable-next-line no-fallthrough
    default:
      const actionWord = capitalize(actionType);
      // let actionLogo = <></>;
      let warning = <></>;

      switch (actionType) {
        case ACTIONS.deposit:
          // actionLogo = <DepositIcon color={color} width={32} />;

          switch (vaultVersion) {
            // case "v2":
            //   warning = (
            //     <WarningContainer
            //       className="mt-2 mb-4 w-100 text-center"
            //       color={color}
            //     >
            //       <PrimaryText fontSize={14} lineHeight={20} color={color}>
            //         Your funds will be deployed in the vault’s next weekly
            //         strategy at 11am UTC on Friday
            //       </PrimaryText>
            //     </WarningContainer>
            //   );
            //   break;
          }
          break;
        case ACTIONS.withdraw:
          // actionLogo = <WithdrawIcon color={color} width={32} />;
          break;
        case ACTIONS.transfer:
          // actionLogo = <TransferIcon color={color} width={32} />;
      }

      return (
        <div className="d-flex flex-column align-items-center">
          {/* Logo */}
          {/* <ActionLogoContainer color={color}>{actionLogo}</ActionLogoContainer> */}

          {/* Title */}
          {/* <FormTitle className="mt-3 text-center">
            Preview {actionWord}
          </FormTitle> */}
          <DeposilTitle>
            Preview {actionWord}
          </DeposilTitle>

          
          <div className="d-flex w-100 flex-row align-items-center justify-content-between mt-4">
            <DeposilText>APR (Gross of fee)</DeposilText>
            <Title className="text-right">
              {optionApy}{" "}
            </Title>
          </div>
          <div className="d-flex w-100 flex-row align-items-center justify-content-between mt-4">
            <DeposilText>Current Cycle's Expiry:</DeposilText>
            <Title className="text-right">
              {expiry(false)}
            </Title>
          </div>
          <div className="d-flex w-100 flex-row align-items-center justify-content-between mt-4">
            <DeposilText>Currently Deposited:</DeposilText>
            <Title className="text-right">
              {loading
                ? loadingText
                : `${formatBigNumber(v2LockedBalanceInAsset, decimals)} ${
                        asset ? getAssetDisplay(asset) : ""
                      }`
              }
            </Title>
          </div>

          

          {/* Info Preview */}
          {/* {vaultOption === "rstETH-THETA" &&
          actionType === "withdraw" &&
          withdrawOption === "instant" ? (
            <div className="d-flex w-100 flex-row align-items-center justify-content-between mt-4">
              <SecondaryText>Estimated {actionWord} Amount</SecondaryText>
              <Title className="text-right">
                {formatBigNumber(amount, getAssetDecimals(asset))}{" "}
                {getAssetDisplay(asset)}
              </Title>
            </div>
          ) : ( */}
            <div className="d-flex w-100 flex-row align-items-center justify-content-between mt-4">
              <DeposilText>{actionWord} Amount：</DeposilText>
              <DeposilValue className="text-right">
                {formatBigNumber(amount, getAssetDecimals(asset))}{" "}
                {getAssetDisplay(asset)}
              </DeposilValue>
            </div>
          {/* )} */}
          {/* {detailRows.map((detail, index) => (
            <div
              className="d-flex w-100 flex-row align-items-center justify-content-between mt-4"
              key={index}
            >
              <SecondaryText>{detail.key}</SecondaryText>
              <Title className="text-right">{detail.value}</Title>
            </div>
          ))} */}

          <CommitButon
            onClick={onClickConfirmButton}
            className="btn py-3 mt-4 mb-3"
          >
            Confirm {actionWord}
          </CommitButon>
          {/* <ActionButton
            onClick={onClickConfirmButton}
            className="btn py-3 mt-4 mb-3"
            color={color}
          >
            Confirm {actionWord}
          </ActionButton> */}
          {warning}
        </div>
      );
  }
};

export default PreviewStep;
