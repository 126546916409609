// import React, { useMemo } from "react";
import { useHistory } from "react-router";
import ProductCatalogue from "shared/lib/components/Product/ProductCatalogue";
// import { Title } from "shared/lib/designSystem";
// import sizes from "shared/lib/designSystem/sizes";
// import styled from "styled-components";
import { getVaultURI } from "../../constants/constants";
import StakingBanner from "../../components/Banner/StakingBanner";

import IconETH from "./../../assets/images/iconETH.png";
import IconBTC from "./../../assets/images/iconBTC.png";
import IconBNB from "./../../assets/images/iconBNB.png";
import IconUSDC from "./../../assets/images/iconUSDC.png";

// const ProductTitle = styled(Title)`
//   display: none;
//   font-size: 24px;
//   text-align: center;
//   margin-top: 16px;

//   @media (max-width: ${sizes.md}px) {
//     display: block;
//   }
// `;

const Homepage = () => {
  const history = useHistory();


  // interface ProductVaultIcon {
  //   ETH: React.ReactNode;
  //   BTC: React.ReactNode;
  //   USDC: React.ReactNode;
  // }

  const vaultIcon = {
    "bETH-THETA": (<img width="48px" height="48px" src={IconETH} alt=""/>),
    "bBTC-THETA": (<img width="48px" height="48px" src={IconBTC} alt=""/>),
    "bBNB-THETA": (<img width="48px" height="48px" src={IconBNB} alt=""/>),
    "bBUSD-ETH-P-THETA": (<img width="48px" height="48px" src={IconUSDC} alt=""/>)
  };
  
  return (
    <div style={{
      minHeight: "75vh",
      backgroundColor: '#043f3d'
    }}>
      <StakingBanner />
      {/* <ProductTitle>PRODUCT</ProductTitle> */}
      <ProductCatalogue
        vaultIcon={vaultIcon}
        variant="webapp"
        onVaultPress={(vault, version) =>
          history.push(getVaultURI(vault, version))
        }
      />
    </div>
  );
};

export default Homepage;
