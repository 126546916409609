// import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

// import { AppLogo } from "shared/lib/assets/icons/logo";
import logo from '../../assets/images/main-icon.svg'

const LogoContainer = styled.div`
  display: flex;
  border-radius: 48px;
`;

const HeaderLogo = () => {
  return (
    <>
      <LogoContainer>
        <Link to="/">
          {/* <AppLogo height="40px" width="40px" /> */}
          <img
            style={{
              height: "3.2rem",
              width: '3.2rem',
            }}
            alt="logo"
            src={logo}
          />
        </Link>
      </LogoContainer>
    </>
  );
};

export default HeaderLogo;
