import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useWeb3Wallet } from "shared/lib/hooks/useWeb3Wallet";
import { parseUnits } from "@ethersproject/units";
import { useLocation } from "react-router-dom";
import colors from "shared/lib/designSystem/colors";
import {
  AVAX_BRIDGE_URI,
  isNativeToken,
  isAvaxNetwork,
  VaultAddressMap,
  VaultAllowedDepositAssets,
  VaultMaxDeposit,
  VaultOptions,
  Chains,
  CHAINS_TO_ID
} from "shared/lib/constants/constants";
import { ExternalIcon } from "shared/lib/assets/icons/icons";
import { ACTIONS } from "../Modal/types";
import useVaultActionForm from "../../../../hooks/useVaultActionForm";
import { BaseLink, 
  // SecondaryText,
   Title } from "shared/lib/designSystem";
import useTokenAllowance from "shared/lib/hooks/useTokenAllowance";
import {
  useV2VaultData,
  useAssetBalance,
} from "shared/lib/hooks/web3DataContext";
import { ERC20Token } from "shared/lib/models/eth";
import { isVaultFull } from "shared/lib/utils/vault";
import { getAssetDisplay, getAssetColor } from "shared/lib/utils/asset";
import { formatBigNumber, isPracticallyZero } from "shared/lib/utils/math";
import VaultApprovalForm from "../common/VaultApprovalForm";
// import ButtonArrow from "shared/lib/components/Common/ButtonArrow";
// import theme from "shared/lib/designSystem/theme";
// import SwapBTCDropdown from "../common/SwapBTCDropdown";
import VaultBasicAmountForm from "../common/VaultBasicAmountForm";
import { VaultValidationErrors } from "../types";
import VaultV2WithdrawForm from "./VaultV2WithdrawForm";
import useVaultPriceHistory from "shared/lib/hooks/useVaultPerformanceUpdate";
import { BigNumber } from "ethers";
import { useTranslation } from "react-i18next";
import { useWeb3React } from "@web3-react/core";
import { getERC20Token } from "shared/lib/hooks/useERC20Token";
import { usePendingTransactions } from "shared/lib/hooks/pendingTransactionsContext";
import { useWeb3Context } from "shared/lib/hooks/web3Context";

const BridgeText = styled.span`
  font-size: 14px;
`;

const BridgeButton = styled.div<{ color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  background: ${(props) => props.color}14;
  border: 1px solid ${(props) => props.color};
  border-radius: 100px;
  color: white;
`;

const FormTabContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  border-bottom: 1px solid rgba(121,148,143, 0.5);
`;

const FormTab = styled.div<{ active: boolean }>`
  padding: 16px 0;
  background-color: ${(props) =>
    props.active ? "rgba(105,236,206, 0.2)" : "none"};
  cursor: pointer;
  text-transform: capitalize;
  border-bottom: ${(props) =>
    props.active ? "1px solid #69ECCE" : "0"};
    box-sizing: border-box;
`;

const FormTabTitle = styled(Title)<{ active: boolean }>`
  font-size: 16px;
  line-height: 21px;
  padding: 0 12px;
  text-transform: capitalize;
  color: ${(props) =>
    props.active ? "#69ECCE" : "white"};
`;

const FormInfoText = styled(Title)`
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: ${(props) => (props.color ? props.color : colors.text)};
`;

// const SwapTriggerContainer = styled.div`
//   display: flex;
//   justify-content: center;
// `;

// const SwapTriggerButton = styled.div`
//   margin-top: 24px;
//   border: ${theme.border.width} ${theme.border.style} ${colors.border};
//   border-radius: 100px;
//   padding: 8px 16px;
//   width: 300px;
//   display: flex;
//   align-items: center;
// `;

// const SwapTriggerButtonText = styled(SecondaryText)`
//   flex: 1;
// `;

interface VaultV2DepositWithdrawFormProps {
  vaultOption: VaultOptions;
  onFormSubmit: () => void;
}

const VaultV2DepositWithdrawForm: React.FC<VaultV2DepositWithdrawFormProps> = ({
  vaultOption,
  onFormSubmit,
}) => {
  /**
   * Primary hooks
   */
  const { handleActionTypeChange, vaultActionForm, handleMaxClick } =
    useVaultActionForm(vaultOption);
  const {
    data: {
      asset,
      assetBackup,
      cap,
      decimals,
      depositBalanceInAsset,
      lockedBalanceInAsset,
      round,
      totalBalance,
      withdrawals,
    },
    loading,
  } = useV2VaultData(vaultOption);
  const { balance: userAssetBalance } = useAssetBalance(
    vaultActionForm.depositAsset || asset
  );
  const { balance: userAssetBackupBalance } = useAssetBalance(
    vaultActionForm.depositAssetBackup || assetBackup
  );
  const vaultBalanceInAsset = depositBalanceInAsset.add(lockedBalanceInAsset);
  const { priceHistory } = useVaultPriceHistory(vaultOption, "v2");
  const { active, chainId, ethereumProvider } = useWeb3Wallet();
  const { t } = useTranslation();
  const vaultMaxDepositAmount = VaultMaxDeposit[vaultOption];
  const isInputNonZero = parseFloat(vaultActionForm.inputAmount) > 0;
  const avalancheColor = getAssetColor(
    // vaultOption === "rsAVAX-THETA" ? "sAVAX" : "WAVAX"
    "USDC"
  );

  const canCompleteWithdraw = useMemo(() => {
    return (
      vaultActionForm.withdrawOption !== "instant" &&
      !withdrawals.shares.isZero() &&
      withdrawals.round !== round
    );
  }, [round, vaultActionForm.withdrawOption, withdrawals]);
  const withdrawalAmount = useMemo(
    () =>
      withdrawals.shares
        .mul(
          priceHistory.find((history) => history.round === withdrawals.round)
            ?.pricePerShare || BigNumber.from(0)
        )
        .div(parseUnits("1", decimals)),
    [decimals, priceHistory, withdrawals.round, withdrawals.shares]
  );

  /**
   * Default to initial state and process initial state
   */
  const [processedInitialState, setProcessedInitialState] = useState(false);
  const location = useLocation();

  const {chainId: chainId2 } = useWeb3React();

  useEffect(() => {
    if (processedInitialState) {
      return;
    }

    const searchParams = new URLSearchParams(location.search);
    try {
      switch (searchParams.get("initialAction")) {
        case "completeWithdraw":
          if (canCompleteWithdraw) {
            handleActionTypeChange(ACTIONS.withdraw, "v2", {
              withdrawOption: "complete",
            });
            handleMaxClick();
            //auto popup dialog
            onFormSubmit();
            setProcessedInitialState(true);
          }
          break;
        default:
          handleActionTypeChange(ACTIONS.deposit, "v2");
          setProcessedInitialState(true);
      }
    } catch {
      handleActionTypeChange(ACTIONS.deposit, "v2");
      setProcessedInitialState(true);
    }
  }, [
    canCompleteWithdraw,
    handleActionTypeChange,
    handleMaxClick,
    location.search,
    onFormSubmit,
    processedInitialState,
  ]);

  /**
 * Side hooks
 */
  const tokenAllowance = useTokenAllowance(
    isNativeToken(vaultActionForm.depositAsset || asset)
      ? undefined
      : ((vaultActionForm.depositAsset?.toLowerCase() ||
          VaultAllowedDepositAssets[
            vaultOption
          ][0].toLowerCase()) as ERC20Token),
    VaultAddressMap[vaultOption].v2
  );
  

  /**
   * Check if approval needed
   */
  const showTokenApproval = useMemo(() => {
    if (vaultActionForm.actionType === ACTIONS.deposit) {
      return (
        !isNativeToken(
          vaultActionForm.depositAsset ||
            VaultAllowedDepositAssets[vaultOption][0]
        ) &&
        tokenAllowance &&
        isPracticallyZero(tokenAllowance, decimals)
      );
    }

    return false;
  }, [
    // asset,
    decimals,
    tokenAllowance,
    vaultActionForm.actionType,
    vaultActionForm.depositAsset,
    vaultOption,
  ]);
  // const [swapContainerOpen, 
  //   setSwapContainerOpen
  // ] = useState(false);

  const error = useMemo((): VaultValidationErrors | undefined => {
    switch (vaultActionForm.actionType) {
      case ACTIONS.deposit:
        /** Vault full */
        if (isVaultFull(totalBalance, cap, decimals)) {
          return "vaultFull";
        }

        /** Max Deposited */
        if (vaultMaxDepositAmount.lt(vaultBalanceInAsset)) {
          return "maxDeposited";
        }
        break;
    }

    try {
      /** Check block with input requirement */
      if (isInputNonZero && !loading && active) {
        const amountBigNumber = parseUnits(
          vaultActionForm.inputAmount,
          decimals
        );

        switch (vaultActionForm.actionType) {
          case ACTIONS.deposit:
            
            if (amountBigNumber.gt(userAssetBalance.add(userAssetBackupBalance))) {
              return "insufficientBalance";
            }

            if (
              amountBigNumber.gt(vaultMaxDepositAmount.sub(vaultBalanceInAsset))
            ) {
              return "maxExceeded";
            }

            if (amountBigNumber.gt(cap.sub(totalBalance))) {
              return "capacityOverflow";
            }

            break;

          case ACTIONS.withdraw:
            switch (vaultActionForm.withdrawOption) {
              case "instant":
                if (amountBigNumber.gt(depositBalanceInAsset)) {
                  return "withdrawLimitExceeded";
                }

                break;
              case "standard":
                if (amountBigNumber.gt(lockedBalanceInAsset)) {
                  return "withdrawLimitExceeded";
                }

                if (canCompleteWithdraw) {
                  return "eixstingWithdraw";
                }

                break;
              case "complete":
                if (amountBigNumber.gt(withdrawalAmount)) {
                  return "withdrawLimitExceeded";
                }
                break;
            }
        }
      }
    } catch (err) {
      // Assume no error because empty input unable to parse
    }

    return undefined;
  }, [
    active,
    canCompleteWithdraw,
    cap,
    decimals,
    depositBalanceInAsset,
    isInputNonZero,
    loading,
    lockedBalanceInAsset,
    totalBalance,
    userAssetBalance,
    vaultActionForm.actionType,
    vaultActionForm.inputAmount,
    vaultActionForm.withdrawOption,
    vaultBalanceInAsset,
    vaultMaxDepositAmount,
    withdrawalAmount,
    userAssetBackupBalance
  ]);

  const networkStatus = useMemo(() => {
    let networkChainId:any;
    try{
      networkChainId = chainId2;
    } catch(e) {
      console.log('error:', e)
    }
    if(networkChainId !== CHAINS_TO_ID[Chains.BSC] && networkChainId !== undefined){
      return false;
    }
    return true;
  }, [chainId2])

  const [depositConvertFlag, setConvertFlag] = useState(false)
  const [approveFlag, setApproveFlag] = useState(false)

  const depositButtonText = useMemo(() => {
    if(decimals === undefined || vaultActionForm.inputAmount === undefined || vaultActionForm.inputAmount === "" || userAssetBalance === undefined) {
      setConvertFlag(false)
      return "Preview Deposit"
    } else {
      const amountBigNumber = parseUnits(
        vaultActionForm.inputAmount,
        decimals
      );
      
      setConvertFlag(amountBigNumber.gt(userAssetBalance))

      console.log('tokenAllowance:', tokenAllowance)
      let allowanceNum = tokenAllowance === undefined ? parseUnits("0", decimals) : tokenAllowance

      setApproveFlag(amountBigNumber.gt(allowanceNum))

      return amountBigNumber.gt(userAssetBalance) ? "Convert BNB to WBNB" : 
              amountBigNumber.gt(allowanceNum) ? "Approve" : "Preview Deposit"//"Preview Deposit"
    }
  }, [userAssetBalance, vaultActionForm.inputAmount, decimals, tokenAllowance])

  const tokenContract = useMemo(() => {
    if (isNativeToken(asset) || !chainId) {
      return;
    }

    return getERC20Token(
      ethereumProvider,
      asset.toLowerCase() as ERC20Token,
      chainId
    );
  }, [chainId, asset, ethereumProvider]);

  const { addPendingTransaction } = usePendingTransactions();
  const { provider } = useWeb3Context();

  const convertBackupAssets = useCallback(async () => {
    console.log("convertBackupAssets")
    if(tokenContract === undefined) return;
    
    const amountBigNumber = parseUnits(
      vaultActionForm.inputAmount,
      decimals
    );

    let depositAmount = amountBigNumber.sub(userAssetBalance);
    
    

    const tx = await tokenContract.deposit({
      value: depositAmount//BigNumber.from('10000000000000000')
    });
    console.log(tx)
    const txhash = tx.hash;

    addPendingTransaction({
      txhash: txhash,
      type: "depositWrappedBNB",
      amount: depositAmount.toString(),//'10000000000000000',
      vault: vaultOption,
      asset: "WBNB",
    });

    // Wait for transaction to be approved
    await provider.waitForTransaction(txhash, 2);
  }, [addPendingTransaction, decimals, provider, tokenContract, userAssetBalance, vaultActionForm.inputAmount, vaultOption])

  const depositAsset = useMemo(() => {

        return (
          vaultActionForm.depositAsset ||
          VaultAllowedDepositAssets[vaultOption][0]
        );
    
  }, [vaultActionForm.depositAsset, vaultOption]);
  const onApprove = useCallback(async () => {
    const approveToAddress = VaultAddressMap[vaultOption]["v2"];
    console.log('---------0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff----------');
    console.log(tokenContract);
    console.log(approveToAddress);

    if (tokenContract && approveToAddress) {
      // setWaitingApproval(true);
      const amount =
        "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";
      try {
        const tx = await tokenContract.approve(approveToAddress, amount);

        const txhash = tx.hash;

        addPendingTransaction({
          txhash,
          type: "approval",
          amount: amount,
          vault: vaultOption,
          asset: depositAsset,
        });

        // Wait for transaction to be approved
        await provider.waitForTransaction(txhash, 2);
      } catch (err) {
      } finally {
        // setWaitingApproval(false);
      }
    }
  }, [    addPendingTransaction,
    depositAsset,
    provider,
    tokenContract,
    vaultOption,
    ])

  // const convertBackupAssets = async () => {
  // }

  const formContent = useMemo(() => {
    /**
     * Approval before deposit
     */
    
    if (showTokenApproval || !networkStatus) {
      return (
        <VaultApprovalForm
          vaultOption={vaultOption}
          version="v2"
          showDepositAssetSwap={
            VaultAllowedDepositAssets[vaultOption].length > 1
          }
        />
      );
    }
    
    switch (vaultActionForm.actionType) {
      case ACTIONS.deposit:
        return (
          <VaultBasicAmountForm
            vaultOption={vaultOption}
            error={error}
            formExtra={{
              label: "Wallet Balance",
              amount: userAssetBalance,
              amountBackup: userAssetBackupBalance,
              unitDisplay: getAssetDisplay(
                vaultActionForm.depositAsset || asset
              ),
              unitDisplayBackup: getAssetDisplay(
                vaultActionForm.depositAssetBackup || assetBackup
              ),
              error: error === "insufficientBalance",
            }}
            showSwapDepositAsset={
              VaultAllowedDepositAssets[vaultOption].length > 1
            }
            onFormSubmit={depositConvertFlag ? convertBackupAssets: approveFlag ? onApprove : onFormSubmit}
            actionButtonText={depositButtonText}
            // getButtonText={getDepositButtonText}
          />
        );
      case ACTIONS.withdraw:
        return (
          <VaultV2WithdrawForm
            vaultOption={vaultOption}
            error={error}
            onFormSubmit={onFormSubmit}
            depositBalanceInAsset={depositBalanceInAsset}
            lockedBalanceInAsset={lockedBalanceInAsset}
            initiatedWithdrawAmount={withdrawalAmount}
            canCompleteWithdraw={canCompleteWithdraw}
          />
        );
    }


 
  }, [
    onApprove,
    convertBackupAssets,
    asset,
    canCompleteWithdraw,
    depositBalanceInAsset,
    error,
    lockedBalanceInAsset,
    onFormSubmit,
    showTokenApproval,
    userAssetBalance,
    vaultActionForm.actionType,
    vaultActionForm.depositAsset,
    vaultOption,
    withdrawalAmount,
    networkStatus,
    depositButtonText,
    assetBackup,
    userAssetBackupBalance,
    vaultActionForm.depositAssetBackup,
    depositConvertFlag,
    approveFlag
  ]);

  const formInfoText = useMemo(() => {
    switch (error) {
      case "vaultFull":
        return (
          <FormInfoText color={colors.red}>
            {t("shared:VaultV2DepositWithdrawForm:currentlyFull")}
          </FormInfoText>
        );
      case "maxDeposited":
        return (
          <FormInfoText color={colors.red}>
            {t("shared:VaultV2DepositWithdrawForm:maxDeposit:explanation", {
              maxDeposit: formatBigNumber(vaultMaxDepositAmount, decimals),
              asset: getAssetDisplay(asset),
            })}
          </FormInfoText>
        );
    }
  }, [asset, decimals, error, vaultMaxDepositAmount, t]);

  const bridgeAvaxCTA = useMemo(() => {
    if (
      vaultActionForm.actionType === ACTIONS.deposit &&
      vaultActionForm.depositAsset &&
      chainId &&
      isAvaxNetwork(chainId)
    ) {
      return (
        <BaseLink
          className="mt-4"
          to={AVAX_BRIDGE_URI}
          target="_blank"
          rel="noreferrer noopener"
        >
          <BridgeButton color={avalancheColor}>
            <BridgeText className="mr-2">
              {t("shared:VaultV2DepositWithdrawForm:bridgeAvalanche")}
            </BridgeText>
            <ExternalIcon color="white" />
          </BridgeButton>
        </BaseLink>
      );
    }
  }, [
    chainId,
    vaultActionForm.actionType,
    vaultActionForm.depositAsset,
    avalancheColor,
    t,
  ]);

  const swapContainerTrigger = useMemo(() => {
    switch (asset) {
      // case "WBTC":
      //   return (
      //     <SwapTriggerContainer>
      //       <SwapTriggerButton
      //         role="button"
      //         onClick={() => setSwapContainerOpen((open) => !open)}
      //       >
      //         <SwapTriggerButtonText>
      //           {t("shared:VaultV2DepositWithdrawForm:swapBTC")}
      //         </SwapTriggerButtonText>
      //         <ButtonArrow
      //           isOpen={swapContainerOpen}
      //           color={colors.primaryText}
      //         />
      //       </SwapTriggerButton>
      //     </SwapTriggerContainer>
      //   );

      default:
        return <></>;
    }
  }, [asset]);

  const swapContainer = useMemo(() => {
    switch (asset) {
      // case "WBTC":
      //   return <SwapBTCDropdown open={swapContainerOpen} />;
      default:
        return <></>;
    }
  }, [asset]);

  return (
    <>
      <FormTabContainer>
        <FormTab
          active={vaultActionForm.actionType === ACTIONS.deposit}
          onClick={() => handleActionTypeChange(ACTIONS.deposit, "v2")}
        >
          <FormTabTitle active={vaultActionForm.actionType === ACTIONS.deposit}>
            {t("shared:VaultV2DepositWithdrawForm:deposit")}
          </FormTabTitle>
        </FormTab>
        <FormTab
          active={vaultActionForm.actionType === ACTIONS.withdraw}
          onClick={() => handleActionTypeChange(ACTIONS.withdraw, "v2")}
        >
          <FormTabTitle
            active={vaultActionForm.actionType === ACTIONS.withdraw}
          >
            {t("shared:VaultV2DepositWithdrawForm:withdraw")}
          </FormTabTitle>
        </FormTab>
      </FormTabContainer>

      <div className="d-flex flex-column p-4 w-100">
        {formContent}
        {bridgeAvaxCTA}
        {formInfoText}
        {swapContainerTrigger}
      </div>

      {swapContainer}
    </>
  );
};

export default VaultV2DepositWithdrawForm;
