// import React, { useEffect } from "react";
// import styled, { keyframes } from "styled-components";
import styled from "styled-components";
// import tree from '../../assets/images/main-icon.svg';
import { LoadingIcon } from "./LoadingIcon";
// import { GeofenceCountry, useGeofence } from "shared/lib/hooks/useGeofence";

const Mask = styled.div`
  width: 100vw;
  height: 100vh;
  background: #043F3D;
  div.mask {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(16,49,47,0.7);
  }
`;

interface Iprops {
  loading: boolean
}


export const MaskLayer: React.FC<Iprops> = ({
  loading
}) => {

  return (
    <div id="wrap" style={{position: "absolute", zIndex: 100}}>
      <Mask>
        { loading }
        <div className="mask"></div>
        <LoadingIcon />
      </Mask> 
    </div>
  );
};