import React from "react";
import styled from "styled-components";

import { getAssetColor, getAssetLogo } from "shared/lib/utils/asset";
import { 
  // Title, 
  Subtitle, BaseIndicator } from "shared/lib/designSystem";
import theme from "shared/lib/designSystem/theme";
import {
  Chains,
  CHAINS_TO_NATIVE_TOKENS,
  ENABLED_CHAINS,
  READABLE_CHAIN_NAMES,
} from "shared/lib/constants/constants";

interface ConnectChainBodyProps {
  onClose?: () => void;
  onSelectChain: (chain: Chains) => void;
  currentChain: Chains | undefined;
  availableChains?: Chains[];
}

const ModalContainer = styled.div`
`;

const NetworkContainer = styled.div<{
  borderColor: string;
  active?: boolean;
}>`
  display: flex;
  padding: 8px;
  padding-right: 16px;
  height: 56px;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  margin-bottom: 16px;
  cursor: pointer;
  border: ${(props) => props.active ? "1px solid #69ECCE" : "1px solid #E2F8F1"};
  &:hover {
    opacity: ${theme.hover.opacity};
  }

`;
const NetworkNameContainer = styled.div`
  display: flex;
  align-items: center;
`;
const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  background: #10312F;
  width: 100%;
  height: 102px;
  font-family: 'MuseoModerno';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 38px;
  text-align: center;
  color: #ffffff;
`;
const NetworkName = styled(Subtitle)`
  font-size: 16px;
  line-height: 24px;
  margin-left: 10px;
  font-family: 'MuseoModerno';
`;
const AssetCircle = styled(BaseIndicator)<{
  size: number;
  color: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const NetworkContainerPill = styled(NetworkContainer)`
  margin: 0 32px 24px;
`;


const ConnectChainBody: React.FC<ConnectChainBodyProps> = ({
  onSelectChain,
  currentChain,
  availableChains = ENABLED_CHAINS,
}) => {

  // console.log('ConnectChainBody')
  // console.log(availableChains);
  // console.log(currentChain);

  return (
    <ModalContainer>
      <TitleContainer>
        Select a blockchain
      </TitleContainer>

      {availableChains.map((chain: Chains) => (
        <ChainButton
          key={chain}
          chain={chain}
          currentChain={currentChain as Chains}
          onSelectChain={() => onSelectChain(chain)}
        ></ChainButton>
      ))}
    </ModalContainer>
  );
};

export const ChainButton: React.FC<{
  chain: Chains;
  currentChain: Chains;
  onSelectChain: (chain: Chains) => void;
}> = ({ chain, currentChain, onSelectChain }) => {
  const Logo = getAssetLogo(CHAINS_TO_NATIVE_TOKENS[chain]);
  const color = getAssetColor(CHAINS_TO_NATIVE_TOKENS[chain]);
  const active = currentChain === chain;
  const logoSize = 40;

  // console.log('ChainButton')
  // console.log(chain)
  // console.log(Logo)
  // console.log(color)
  // console.log(READABLE_CHAIN_NAMES[chain])


  return (
    <NetworkContainerPill
      key={chain}
      onClick={() => onSelectChain(chain)}
      borderColor={color}
      active={active}
    >
      <NetworkNameContainer>
        <AssetCircle size={logoSize} color={`${color}1F`}>
          <Logo
            style={{ padding: 0 }}
            height={logoSize}
            width={logoSize}
          ></Logo>
        </AssetCircle>
        <NetworkName>{READABLE_CHAIN_NAMES[chain]}</NetworkName>
      </NetworkNameContainer>

      {/* {active && <BaseIndicator size={8} color={color}></BaseIndicator>} */}
    </NetworkContainerPill>
  );
};

export default ConnectChainBody;
